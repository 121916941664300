// React
import { Fragment, useMemo, useState } from "react"
import { useNavigate } from "@/lib/router"

import { Helmet } from "@/lib/seo"
import { useTrans } from "@/i18n"

import { FiChevronDown } from "@/lib/icons"

// Queries
import { useMessagesQuery, MessageType } from "@/api/graphql"

// DateTime
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/constants/constants"

// Tables
import {
	CellProps,
	useExpanded,
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
	UseTableOptions,
} from "react-table"
import {
	Table,
	TableBody,
	TableDataCell,
	TableRowCell,
} from "@/components/table-controls/TableItems"

// UI
import { CardBody, CardWrapper, Card } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { SearchInput } from "@/components/form-controls/Input"
import { Button } from "@/components/Button"
import { PaginationAsButtons } from "@/components/PaginationAsButtons"

// TODO: Remove this
const MessageStateEnum = {
	Unread: "UNREAD",
	Archived: "ARCHIVED",
	Trashed: "TRASHED",
} as const

/**
 * AllCommunications
 * @returns
 */
export const AllCommunications = () => {
	// State
	const [filter, setFilter] = useState<string | undefined>()
	const [sort, setSort] = useState<string | undefined>()
	const limit = 10
	const [currentPage, setCurrentPage] = useState<number>(0)

	// Translations
	const t = useTrans(["project", "common"])

	// Query
	const { data } = useMessagesQuery({
		count: limit,
		offset: currentPage * limit,
		subject: filter,
	})

	// Memo
	const messages = useMemo(() => {
		return data?.me?.messages?.list?.results
	}, [data?.me?.messages?.list?.results])

	// PaginatorInfo
	const navigate = useNavigate()
	const totalMessagesCount = useMemo(
		() => data?.me?.messages?.list?.total_count ?? 0,
		[data?.me?.messages?.list?.total_count],
	)

	const columns = useMemo(() => {
		const cols: UseTableOptions<MessageType>["columns"] = [
			{
				accessor: "subject",
			},
			{
				id: "received_at",
				accessor: (data) =>
					data?.received_at
						? DateTime.fromISO(data?.received_at).toFormat(
								dateFormat,
						  )
						: "",
			},
		]

		return cols.filter(Boolean)
	}, [])

	// Table
	const {
		getTableProps,
		getTableBodyProps,
		page,
		state: { pageSize, pageIndex },
		prepareRow,
	} = useTable(
		{
			columns,
			data: messages as MessageType[],
			defaultColumn: {
				Cell: ({ value }: CellProps<MessageType, string | number>) => (
					<TableDataCell>{value}</TableDataCell>
				),
			},
		},
		useGlobalFilter,
		useSortBy,
		useExpanded,
		usePagination,
	)

	return (
		<>
			<Helmet>
				<title>{t("project:project.communications.heading")}</title>
			</Helmet>
			<CardWrapper>
				<CardBody>
					<div className="md:flex">
						<Heading as="h5" className="mb-3 sm:truncate">
							{t("project:project.communications.heading")}
						</Heading>
						<div className="mb-4 ml-auto flex gap-4">
							<div className="sm:ml-auto">
								<SearchInput
									disabled // TODO: Re-enable when we can search on partial words as well
									onChange={(event) => {
										if (
											event.currentTarget.value.charAt(
												event.currentTarget.value
													.length - 1,
											) === " "
										) {
											setFilter(event.currentTarget.value)
										}
									}}
									label={"Zoeken"}
									className="md:width-auto min-w-full"
								/>
							</div>

							{/** Filter on project status */}
							<div className="relative">
								<label htmlFor="projectStatus">
									<Button
										size="small"
										variant="transparent"
										disabled
									>
										{sort === undefined
											? "Sorteren"
											: t(
													`common:common.project.status.${sort}`,
											  )}
										<FiChevronDown className={"ml-2"} />
										<select
											disabled
											name="projectStatus"
											className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
											onChange={(evt) => {
												setSort(evt.currentTarget.value)
											}}
											value={sort ?? undefined}
										>
											<option value={undefined}>
												Sorteren
											</option>
											{Object.entries(
												MessageStateEnum,
											).map(([_key, value]) => (
												<option
													value={value}
													key={value}
												>
													{t(
														`common:common.project.status.${value}`,
													)}
												</option>
											))}
										</select>
									</Button>
								</label>
							</div>
						</div>
					</div>

					{/** When user has no messages */}
					{totalMessagesCount === 0 && (
						<Card>
							<p className="text-sm text-gray-500">
								<div className="z-2 relative px-8">
									<Heading as="h4" className="text-center">
										{t(
											"project:project.communications.no-data.title",
										)}
									</Heading>
									<p className="mt-2 text-center text-sm text-gray-500">
										{t(
											"project:project.communications.no-data.copy",
										)}
									</p>
								</div>
							</p>
						</Card>
					)}

					<Table
						{...getTableProps({
							className: "min-w-[64rem] lg:min-w-0",
						})}
						data-testid="tablebody"
					>
						<TableBody
							{...getTableBodyProps()}
							data-testid="tablebody-overview"
							data-pageindex={pageIndex}
						>
							{page.map((row) => {
								prepareRow(row)

								const isOdd = row.index % 2 === 0
								return (
									<Fragment key={row.id}>
										<TableRowCell
											isOdd={isOdd}
											className={"cursor-pointer"}
											onClick={() =>
												navigate(
													`${row.original.message_id}`,
												)
											}
										>
											{row.cells.map((cell, index) => {
												return (
													<Fragment key={index}>
														{cell.render("Cell")}
													</Fragment>
												)
											})}
										</TableRowCell>
									</Fragment>
								)
							})}
							{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
							{page.length < pageSize && pageIndex !== 0 ? (
								<>
									{Array(Math.max(pageSize - page.length, 1))
										.fill(true)
										.map((_, index) => (
											<TableRowCell
												key={index}
												withHover={false}
												isOdd={index % 2 === 0}
											>
												<TableDataCell
													colSpan={columns.length}
												>
													<span className="dummy-text" />
												</TableDataCell>
											</TableRowCell>
										))}
								</>
							) : null}
						</TableBody>
					</Table>
					<CardBody>
						{messages?.length !== 0 && (
							<PaginationAsButtons
								countPerPage={limit}
								totalCount={totalMessagesCount ?? 0}
								itemType={
									"common.pagination.item_types.message"
								}
								currentPage={currentPage + 1}
								currentItemsAmount={messages?.length ?? 0}
								onNextPage={() =>
									setCurrentPage(
										(currentPage) => currentPage + 1,
									)
								}
								onPrevPage={() =>
									setCurrentPage((currentPage) =>
										Math.max(currentPage - 1, 0),
									)
								}
								analyticsId="investments"
							/>
						)}
					</CardBody>
				</CardBody>
			</CardWrapper>
		</>
	)
}
