// Query
import { QueryFunctionContext } from "@/lib/query"

// State
import { store } from "@/state/store"

// Cookies
import { get } from "@/lib/cookies"

// Network errors
import { NetworkError } from "@/api/rest/network-errors"

// Environment variables
import { GRAPHQL_URL } from "@/lib/env"

// Sentry
import { addBreadcrumb, captureMessage } from "@sentry/react"

async function api<Data, Variables>(
	query: string,
	variables?: Variables,
	signal?: AbortSignal,
) {
	// Set headers
	const headers = new Headers()
	headers.set("Content-Type", "application/json")
	headers.set("Accept", "application/json")
	headers.set("X-CSRFToken", get("csrftoken") || "") // TODO: This hack is here to allow cookies from Django to work

	// Set auth token
	const authToken = store.getState().auth.token
	if (authToken !== null) {
		headers.set("Authorization", `Token ${authToken}`)
	}

	// Add Sentry breadcrumb
	addBreadcrumb({
		category: "graphql",
		message: "GraphQL query",
		data: {
			query,
			variables,
		},
	})

	// Fetch response
	const res = await fetch(GRAPHQL_URL as string, {
		method: "POST",
		credentials: "include",
		mode: "cors",
		headers,
		signal,
		body: JSON.stringify({
			query,
			variables,
		}),
	})

	// Parse JSON
	const json = await res.json()

	// Report any errors that the API returns
	reportGraphQLErrors(json)

	// Return json
	return {
		data: json.data as Data,
		errors: json.errors,
	}
}

/**
 * Report GraphQLerrors to Sentry
 *
 * @param json
 */
function reportGraphQLErrors(json: any) {
	if (json?.errors) {
		for (const error of json.errors) {
			// If we encounter a network error, we don't want to report it
			if (
				!String(error?.extensions?.type).includes(
					NetworkError.AUTHENTICATION_ERROR,
				)
			) {
				// All other errors, log and report
				console.error(error.message)

				// Capture message in Sentry
				captureMessage(error.message, {
					level: "error",
					extra: {
						locations: error?.locations,
						path: error?.path,
					},
				})
			}
		}
	}
}

export function mutationFetcher<Data, Variables>(
	query: string,
	variables?: Variables,
) {
	return function mutationFetcherInner() {
		return api<Data, Variables>(query, variables)
	}
}

export function queryFetcher<Data, Variables>(
	query: string,
	variables?: Variables,
) {
	return function queryFetcherInner(
		context?: QueryFunctionContext<
			string | Array<string> | any | undefined
		>,
	) {
		return api<Data, Variables>(query, variables, context?.signal).then(
			(response) => {
				if (response.errors) {
					// Return a rejected promise with the errors
					return Promise.reject(response.errors)
				}
				return response.data
			},
		)
	}
}
