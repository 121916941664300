import { HTMLProps, useEffect, useState } from "react"
import { motion } from "@/lib/animations"

// Router
import { useNavigate, useLocation, Link } from "@/lib/router"
import { Routes } from "@/constants/routes"

import { useSignIn } from "@/context/signIn"
import { useTrans } from "@/i18n"
import { sendEvent } from "@/lib/analytics"
import { useAuth } from "@/context/auth"

// State
import { useDispatch, useSelector } from "@/state/StateProvider"
import {
	setEphemeralToken,
	setRememberMe,
	AuthSliceState,
} from "@/state/features/authSlice"

// UI
import { classNames } from "@/lib/classnames"
import { className as AclassName } from "../Anchor"
import { Checkbox } from "../form-controls/Checkbox"
import { FormikError } from "../form-controls/Errors"
import {
	FormikErrors,
	FormikInput,
	FormikLabel,
	FormikSubmitButton,
} from "../form-controls/formik"

// Forms
import { FormikProvider, useFormik } from "formik"
import { ShowPassword } from "../ShowPassword"

// Rest API
import { apiAuthLoginCreate } from "@/api/rest/generated/api/api"

// Types
import { MethodEnum, LoginResponse } from "@/api/rest/generated/@types"

function isLogin2FARequired(response: unknown): response is LoginResponse {
	return (
		(response as LoginResponse)?.method?.length > 0 &&
		(response as LoginResponse)?.ephemeral_token?.length > 0
	)
}

export function isLogInStateVerified(
	response: unknown,
): response is LoginResponse {
	return (response as LoginResponse)?.method === MethodEnum.email
}

const getInitialValues = (initialEmail: string | null) => {
	return {
		email: initialEmail || "",
		password: "",
		errors: { local: "", common: "" },
	}
}

/**
 * SignInForm
 * @returns
 */
export const SignInForm = () => {
	const auth = useAuth()
	const navigate = useNavigate()
	const location = useLocation()
	const [showPassword, setPasswordVisibility] = useState(false)
	const t = useTrans("sign-in")
	const {
		setState,
		initialEmail: email,
		setInitialEmail,
		setMethod,
	} = useSignIn()

	// State
	const dispatch = useDispatch()
	const rememberMe = useSelector(
		({ auth }: { auth: AuthSliceState }) => auth.rememberMe,
	)

	const form = useFormik({
		initialValues: getInitialValues(email),
		onSubmit: async (values, helpers) => {
			sendEvent("sign-in", "form-submit")
			try {
				setInitialEmail(values.email)

				const response = await apiAuthLoginCreate({
					email: values.email,
					password: values.password,
				})

				if (!response) {
					helpers.setFieldError(
						"errors.common",
						"common.form_errors.unknown_error",
					)
					return
				}

				// Login without MFA
				if (response.auth_token) {
					auth.setAuth({ authToken: response.auth_token })
					navigate(
						(location.state as { from: string })?.from ?? "/",
						{
							replace: true,
						},
					)
				}

				if (isLogin2FARequired(response)) {
					sendEvent("sign-in", "form-success", { label: "verified" })
					dispatch(setEphemeralToken(response.ephemeral_token))
					setState("confirm")
					setMethod(response.method as MethodEnum)
				} else {
					helpers.setFieldError(
						"errors.common",
						"common.form_errors.unknown_error",
					)
				}
			} catch (error: any) {
				let json = await error?.json?.()
				const message = json?.message ?? json?.error

				sendEvent("sign-in", "form-submit-failed", {
					label: message ?? "unknown",
				})

				if (
					message === "user_not_found" ||
					message === "Unable to login with provided credentials."
				) {
					helpers.setFieldError(
						"errors.local",
						"sign-in.form_errors.user_not_found",
					)
				} else if (message) {
					helpers.setFieldError("errors.local", message)
				} else {
					helpers.setFieldError(
						"errors.common",
						"common.form_errors.unknown_error",
					)
				}
			}
		},
	})

	useEffect(() => {
		if (form.errors?.errors) {
			if (typeof window !== "undefined" && window.scrollTo) {
				window.scrollTo(0, document.body.scrollHeight)
			}
		}
	}, [form.errors])

	const handleRememberMeChangeWithAnalytics = () => {
		const nextValue = !rememberMe
		sendEvent("sign-in", "onchange-remember-me", {
			label: String(nextValue),
		})
		dispatch(setRememberMe(nextValue))
	}

	return (
		<>
			<FormikProvider value={form}>
				<form onSubmit={form.handleSubmit}>
					<motion.div className="mb-5">
						<FormikLabel
							htmlFor="email"
							className="block"
							data-testid="sign_in.email.label"
						>
							{t("sign-in:sign-in.form_field.email.label")}
						</FormikLabel>
						<div>
							<FormikInput
								name="email"
								type="email"
								autoComplete="email"
								required
								className="block w-full"
								placeholder={t(
									"sign-in:sign-in.form_field.email.placeholder",
								)}
								data-testid="sign_in.email"
								aria-label="email"
								autoFocus
							/>
							<FormikError field="email" namespace="sign-in" />
						</div>
					</motion.div>

					<motion.div className="mb-5 flex flex-wrap">
						<div className="order-1 flex items-center">
							<FormikLabel
								htmlFor="password"
								className="mr-3 flex-wrap"
								data-testid="sign_in.password.label"
							>
								{t("sign-in:sign-in.form_field.password.label")}
							</FormikLabel>
						</div>
						<div className="order-3 w-full">
							<FormikInput
								name="password"
								type={showPassword ? "text" : "password"}
								autoComplete="current-password"
								required
								className="block w-full"
								data-testid="sign_in.password"
								aria-label="password"
							/>
							<FormikError field="password" namespace="sign-in" />
						</div>
						<div className="order-2 ml-auto">
							<ShowPassword
								name="showPassword"
								display={showPassword}
								setDisplay={setPasswordVisibility}
							/>
						</div>
					</motion.div>
					<div className="flex flex-col justify-between space-y-4 sm:flex-row sm:items-center md:mb-5 md:space-y-0">
						<Link
							to={Routes.RecoverPassword}
							className={classNames(
								AclassName,
								"block text-gray-500 hover:text-gray-900 sm:order-2",
							)}
						>
							{t(
								"sign-in:sign-in.form_field.forgot_password.text",
							)}
						</Link>
						<LocalLabel
							htmlFor="rememberMe"
							className="mr-4 flex items-center sm:order-1"
							data-testid="sign_in.remember_me.label"
						>
							<Checkbox
								id="rememberMe"
								name="rememberMe"
								checked={rememberMe === true}
								onChange={handleRememberMeChangeWithAnalytics}
								onBlur={form.handleBlur}
								data-testid="sign_in.remember_me"
								aria-label="rememberMe"
								className="mr-2"
							/>
							{t("sign-in:sign-in.form_field.remember_me.label")}
						</LocalLabel>
					</div>
					<div className="pb-4">
						<FormikErrors i18nNamespace="sign-in" />
					</div>
					<FormikSubmitButton
						data-testid="sign_in.submit"
						className="flex w-full"
						disabled={false}
					>
						{t("sign-in:sign-in.form_action.submit")}
					</FormikSubmitButton>
				</form>
			</FormikProvider>
		</>
	)
}

const LocalLabel = ({ className, ...rest }: HTMLProps<HTMLLabelElement>) => {
	return (
		<label
			{...rest}
			className={classNames(
				className,
				"mb-1 block cursor-pointer text-gray-500 hover:text-gray-900",
			)}
		/>
	)
}
