// Router
import { Routes } from "@/constants/routes"

// UI
import { PageTemplateLink } from "@/templates/PageTemplate"

// Translations
import { useTrans } from "@/i18n"

/**
 * StatsMenuTabs
 * @returns
 */
export function StatsMenuTabs() {
	const t = useTrans("common")

	return (
		<>
			<PageTemplateLink
				to={Routes.StatisticsPlatform}
				name={t("common:common.navigation.statistics.platform.link")}
				analyticsId={t(
					"common:common.navigation.statistics.platform.link",
				)}
			/>
			<PageTemplateLink
				to={Routes.StatisticsPlatformEndex}
				name={t(
					"common:common.navigation.statistics.platform.endex.link",
				)}
				analyticsId={t(
					"common:common.navigation.statistics.platform.endex.link",
				)}
			/>
		</>
	)
}
