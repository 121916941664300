// Translations
import { useTrans, Trans } from "@/i18n"

// Assets
import BrandLogo from "@/assets/icons/brand/logo.svg?react"

// UI
import { Container } from "@/components/Container"

// Environment
import {
	API_URL,
	AUTH_LOGIN_URL,
	EXTERNAL_PLATFORM_ZONHUB_INVESTMENT,
	EXTERNAL_PLATFORM_ZONHUB_FINANCE,
} from "@/lib/env"

/**
 * HeaderNoAuth
 * @returns
 */
export const HeaderNoAuth = () => {
	const t = useTrans("common")

	// Define Navigation pages
	const pages = [
		{
			label: t("common:common.nav.header.investments"),
			href: EXTERNAL_PLATFORM_ZONHUB_INVESTMENT,
		},
		{
			label: t("common:common.nav.header.finance"),
			href: EXTERNAL_PLATFORM_ZONHUB_FINANCE,
		},
	]

	// Template
	return (
		<div className="relative">
			<nav className="fixed inset-x-0 top-0 z-50 bg-white shadow">
				<Container className="flex h-16 items-center justify-between">
					{/* Logo */}
					<a href={API_URL} className="flex">
						<BrandLogo className="mr-2 h-6 w-6" />
						<Trans
							ns="common"
							i18nKey="common.layout.title"
							components={{
								thin: <span className="font-medium" />,
							}}
						/>
					</a>

					{/* Mobile menu button */}
					<button
						type="button"
						className="focus:ring-primary-500 inline-flex items-center justify-center rounded-md p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset md:hidden"
						aria-label="Toggle navbar visibility"
					>
						<span className="block h-0.5 w-6 bg-current"></span>
						<span className="mt-1.5 block h-0.5 w-6 bg-current"></span>
						<span className="mt-1.5 block h-0.5 w-6 bg-current"></span>
					</button>

					{/* Desktop menu */}
					<div className="hidden items-center md:flex">
						<div className="flex items-center space-x-8">
							{/** Nav items */}
							{pages.map(({ label, href }) => (
								<a
									key={label}
									href={href}
									className="text-gray-600 transition hover:text-gray-900"
								>
									{label}
								</a>
							))}

							<span className="text-gray-300">|</span>

							<a
								href={AUTH_LOGIN_URL}
								className="text-primary-600 hover:text-primary-700 font-medium transition"
							>
								{t("common:common.nav.dashboard")}
							</a>
						</div>
					</div>
				</Container>
			</nav>
		</div>
	)
}
