// React
import { useMemo } from "react"

// SEO
import { Helmet } from "@/lib/seo"

// GraphQL
import {
	useFinanceBondsLoanQuery,
	useFinanceBondsCurrentPaymentQuery,

	// enums
	ProjectInterestPaymentCalculationTypeEnum,
	PaymentStateEnum,
	ProjectStateEnum,
} from "@/api/graphql"

// Translations
import { useTrans, Trans } from "@/i18n"
import { useLang } from "@/context/lang"

// Router
import { Routes } from "@/constants/routes"

// UI
import { TableWarning } from "@/components/table-controls/TableWarning"
import { Anchor, Link } from "@/components/Anchor"
import { PageTemplate } from "@/templates/PageTemplate"
import { FinanceProjectSubMenuTabs } from "../components/FinanceProjectSubMenuTabs"
import { Card } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { Tooltip } from "@/components/tooltips/Tooltip"
import { FinanceBondsLoanByYearTable } from "../components/FinanceBondsLoanByYearTable"
import { Button } from "@/components/Button"

// Icons
import { FiFile, FiInfo, CurrencyEuroIcon } from "@/lib/icons"

// Dates
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/constants/constants"

// Hooks
import { useCurrentFinanceProjectId } from "../hooks/useCurrentFinanceProjectId"

/**
 * FinanceBondsLoan
 * @returns
 */
export function FinanceBondsLoan() {
	// Translation
	const { formatCurrency, formatNumber } = useLang()
	const t = useTrans(["finance", "common"])

	// Query
	const projectId = useCurrentFinanceProjectId()
	const { data } = useFinanceBondsLoanQuery({
		id: String(projectId),
	})

	// Current project
	const currentProject = useMemo(() => {
		return data?.me?.finance_projects?.results?.at(0)
	}, [data?.me?.finance_projects])
	const currentInterestPeriod = currentProject?.interest_periods?.find(
		(period) => period?.is_current_period,
	)

	// Find the index of the current interest period
	const currentIndex =
		currentProject?.interest_periods?.findIndex(
			(period) => period === currentInterestPeriod,
		) || 0

	// Get the last interest period if it exists
	const lastInterestPeriod =
		currentIndex > 0
			? currentProject?.interest_periods?.[currentIndex - 1]
			: null
	const interestPeriodEndDate = DateTime.fromISO(currentInterestPeriod?.end)

	// Template
	return (
		<>
			<Helmet>
				<title>
					{t("finance:finance.bonds-loan.title", {
						projectName: currentProject?.name,
					})}
				</title>
			</Helmet>
			<PageTemplate
				title={t("finance:finance.bonds-loan.title", {
					projectName: currentProject?.name,
				})}
				backHref={Routes.FinanceProjects}
				tabs={<FinanceProjectSubMenuTabs />}
			>
				<div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
					{/** Funds */}
					<Card>
						<Heading
							as="h5"
							className="mb-3 text-gray-600 sm:truncate"
						>
							{t(
								"finance:finance.bonds-loan.block.funding.title",
							)}
						</Heading>
						<div className="grid grid-cols-2 gap-2">
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"finance:finance.bonds-loan.block.funding.original_amount",
								)}
							</dt>
							<dd className="text-right text-sm text-gray-900">
								{formatCurrency(
									Number(currentProject?.total_amount),
								)}
							</dd>

							<dt className="text-sm font-medium text-gray-500">
								{t(
									"finance:finance.bonds-loan.block.funding.loan_outstanding",
								)}
								<Tooltip
									text={t(
										"finance:finance.bonds-loan.block.funding.loan_outstanding.tooltip",
										{
											dateEndOfLastYear: DateTime.now()
												.minus({
													years: 1,
												})
												.endOf("year")
												.toFormat("dd-MM-yyyy")
												.toString(),
										},
									)}
								>
									<FiInfo className="ml-1" />
								</Tooltip>
							</dt>

							<dd className="text-right text-sm text-gray-900">
								{formatCurrency(
									Number(
										currentProject?.fiscal_overview?.at(0)
											?.value_end,
									) || 0,
								)}
							</dd>

							{/** Amortization type */}
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"common:common.project.amortization_schedule",
								)}
							</dt>

							<dd className="text-right text-sm text-gray-900">
								<>
									{t(
										`common:common.project.amortization_schedule.${currentProject?.amortization_schedule}`,
									)}
								</>
							</dd>

							{/** Duration Loan */}
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"finance:finance.bonds-loan.block.funding.loan_duration.title",
								)}
							</dt>

							<dd className="text-right text-sm text-gray-900">
								{t(
									"finance:finance.bonds-loan.block.funding.loan_duration.years",
									{
										years: currentProject?.duration,
									},
								)}
							</dd>

							{/* Issuance date */}
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"finance:finance.bonds-loan.block.funding.start",
								)}
							</dt>

							<dd className="text-right text-sm text-gray-900">
								{DateTime.fromISO(
									currentProject?.start,
								).toFormat(dateFormat)}
							</dd>

							{/** Expiration date */}
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"finance:finance.bonds-loan.block.funding.end",
								)}
							</dt>

							<dd className="text-right text-sm text-gray-900">
								{DateTime.fromISO(currentProject?.end).toFormat(
									dateFormat,
								)}
							</dd>

							{/** Interest payment calculation type */}
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"finance:finance.bonds-loan.block.funding.calculation_type.title",
								)}
							</dt>
							<dd className="text-right text-sm text-gray-900">
								{(() => {
									switch (
										currentProject?.interest_payment_calculation_type
									) {
										case ProjectInterestPaymentCalculationTypeEnum.EndexEnergyCost:
											return (
												<>
													<Trans
														ns="finance"
														i18nKey={`finance.bonds-loan.block.funding.calculation_type.type.endex-energy-cost`}
														values={{
															fixed_surcharge:
																formatCurrency(
																	currentProject.fixed_surcharge ||
																		0,
																),
														}}
														components={{
															Link: (
																<Link
																	href={`${Routes.FinanceSolarInterestTariff}/${currentProject.id}`}
																	className="text-secondary-300 hover:text-secondary-900 text-sm font-medium"
																/>
															),
														}}
													/>
												</>
											)
										case ProjectInterestPaymentCalculationTypeEnum.FixedEnergyCost:
											return (
												<>
													{t(
														"finance:finance.bonds-loan.block.funding.calculation_type.type.fixed-energy-cost",
														{
															cents: formatNumber(
																currentProject.fixed_energy_cost_per_kwh ||
																	0,
															),
														},
													)}
												</>
											)
										case ProjectInterestPaymentCalculationTypeEnum.FixedInterestAmount:
											return (
												<>
													{t(
														"finance:finance.bonds-loan.block.funding.calculation_type.type.fixed-interest-amount",
														{
															fixed_interest_amount_per_share:
																formatCurrency(
																	currentProject.fixed_interest_amount_per_share ||
																		0,
																),
														},
													)}
												</>
											)
										case ProjectInterestPaymentCalculationTypeEnum.FixedInterestRate:
											return (
												<>
													{t(
														"finance:finance.bonds-loan.block.funding.calculation_type.type.fixed-interest-rate",
														{
															percentage:
																currentProject?.fixed_interest_rate?.toFixed(
																	1,
																),
														},
													)}
												</>
											)
										default:
											return null
									}
								})()}
							</dd>
						</div>
					</Card>

					{/** Interest period */}
					{currentProject?.state ===
						ProjectStateEnum.DirectlyFinanced ||
					currentProject?.state === ProjectStateEnum.PaidOff ? (
						<Card>
							<div className="space-y-3">
								<Heading as="h5" className="text-gray-600">
									{t(
										"finance:finance.bonds-loan.block.project_status.title",
									)}
								</Heading>
								<div className="bg-primary-500 inline-block rounded-md px-4 py-1 font-bold text-black opacity-90 shadow-2xl">
									{currentProject?.state}
								</div>
							</div>
						</Card>
					) : (
						<Card>
							<div className="space-y-3">
								<Heading as="h5" className="text-gray-600">
									{t(
										"finance:finance.bonds-loan.block.interest_periods.current.title",
									)}
								</Heading>
								<div className="grid grid-cols-2 gap-2">
									<dt className="text-sm font-medium text-gray-500">
										{t(
											"finance:finance.bonds-loan.block.interest_periods.date_end",
										)}
									</dt>
									<dd className="text-right text-sm text-gray-900">
										{interestPeriodEndDate.toFormat(
											dateFormat,
										)}
									</dd>

									<dt className="text-sm font-medium text-gray-500">
										{t(
											"finance:finance.bonds-loan.block.interest_periods.date_payment",
										)}
									</dt>
									<dd className="text-right text-sm text-gray-900">
										{DateTime.fromISO(
											currentInterestPeriod?.payment_deadline_date,
										).toFormat(dateFormat)}
									</dd>
								</div>
								<Heading as="h5" className="text-gray-600">
									{t(
										"finance:finance.bonds-loan.block.interest_periods.last.title",
									)}
								</Heading>
								<div className="grid grid-cols-2 gap-2">
									{/** Last Interest period End date */}
									<dt className="text-sm font-medium text-gray-500">
										{t(
											"finance:finance.bonds-loan.block.interest_periods.last.date_end",
										)}
									</dt>
									<dd className="text-right text-sm text-gray-900">
										{DateTime.fromISO(
											lastInterestPeriod?.end,
										).toFormat(dateFormat)}
									</dd>

									{/** Last payment Amortization */}
									<dt className="text-sm font-medium text-gray-500">
										{t(
											"finance.bonds-loan.block.interest_periods.last.amortization",
										)}
									</dt>
									<dd className="text-right text-sm text-gray-900">
										{formatCurrency(
											lastInterestPeriod?.amortization ||
												0,
										)}
									</dd>

									{/** Last Interest amount */}
									<dt className="text-sm font-medium text-gray-500">
										{t(
											"finance.bonds-loan.block.interest_periods.last.interest_amount",
										)}
									</dt>
									<dd className="text-right text-sm text-gray-900">
										{formatCurrency(
											Number(
												lastInterestPeriod?.interest_amount,
											) || 0,
										)}
									</dd>

									{/** Last repayment amount */}
									<dt className="text-sm font-medium text-gray-500">
										{t(
											"finance.bonds-loan.block.interest_periods.last.repayment",
										)}
									</dt>
									<dd className="text-right text-sm text-gray-900">
										{formatCurrency(
											lastInterestPeriod?.repayment || 0,
										)}
									</dd>

									{/** Last total sum */}
									<dt className="text-sm font-medium text-gray-500">
										{t(
											"finance:finance.bonds-loan.block.interest_periods.last.interest_payment",
										)}
									</dt>
									<dd className="border-t-2 text-right text-sm text-gray-900 ">
										{formatCurrency(
											(lastInterestPeriod?.amortization ||
												0) +
												(Number(
													lastInterestPeriod?.interest_amount,
												) || 0) +
												(lastInterestPeriod?.repayment ||
													0),
										)}
									</dd>

									{/** Try to load PaymentButton based on name lookup */}
									{currentProject?.name ? (
										<>
											<dt></dt>
											<dd className="text-right">
												<PaymentButton
													projectName={
														currentProject?.name
													}
												/>
											</dd>
										</>
									) : null}
								</div>
							</div>
						</Card>
					)}

					{/** Documents */}
					<Card>
						<Heading
							as="h5"
							className="mb-3 text-gray-600 sm:truncate"
						>
							{t("finance.bonds-loan.block.documents.title")}
						</Heading>
						<div className="grid gap-2">
							{/** Info memorandum */}
							<div className="flex">
								<Anchor
									href={String(
										currentProject?.pdf_memorandum,
									)}
									target="_blank"
									download
									className="text-secondary-300 hover:text-secondary-900 flex items-center text-sm font-medium"
								>
									<FiFile className="mr-2" />
									{t(
										"finance:finance.bonds-loan.block.documents.info_memorandum.title",
									)}
								</Anchor>
							</div>

							{/** Service agreement */}
							{currentProject?.pdf_service_agreement ? (
								<div className="flex">
									<Anchor
										href={String(
											currentProject?.pdf_service_agreement,
										)}
										target="_blank"
										download
										className="text-secondary-300 hover:text-secondary-900 flex items-center text-sm font-medium"
									>
										<FiFile className="mr-2" />
										{t(
											"finance:finance.bonds-loan.block.documents.service_agreement.title",
										)}
									</Anchor>
								</div>
							) : null}
						</div>
					</Card>
				</div>

				{/** Fiscal year overview */}
				<Card className="mt-8">
					<Heading as="h5" className="mb-3 text-gray-600 sm:truncate">
						{t(
							"finance:finance.bonds-loan.block.fiscal-overview.title",
						)}
					</Heading>
					<FinanceBondsLoanByYearTable
						fiscalOverview={currentProject?.fiscal_overview}
					/>
				</Card>

				<TableWarning
					className="mb-3 mt-8"
					message={t("finance:finance.bonds-loan.disclaimer")}
				/>
			</PageTemplate>
		</>
	)
}

/**
 * PaymentButton
 * @param param0
 * @returns
 */
function PaymentButton({ projectName }: { projectName: string }) {
	const t = useTrans("payments")

	const { data } = useFinanceBondsCurrentPaymentQuery({
		q: projectName,
		state: [PaymentStateEnum.PendingOwnerPayment],
		limit: null,
	})
	const latestInterestPayment = data?.interest_payments?.results?.at(0)

	// Show button when status is PendingOwnerPayment
	if (
		latestInterestPayment?.state === PaymentStateEnum.PendingOwnerPayment &&
		latestInterestPayment.payment_link
	) {
		return (
			<a href={latestInterestPayment.payment_link}>
				<Button variant="primary" size="small">
					<CurrencyEuroIcon
						aria-hidden="true"
						className="mr-1 h-5 w-5"
					/>
					{t("payments.list.actions.payment")}
				</Button>
			</a>
		)
	}

	// Default
	return null
}
