import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
	useMemo,
} from "react"
import { useSearchParams, useLocation, useNavigate } from "@/lib/router"

const HiddenGetParamsContext = createContext<HiddenGetParamsContextType>(null!)
interface HiddenGetParamsContextType {
	next?: string
}

export const useHiddenGetParamsContext = () =>
	useContext(HiddenGetParamsContext)

export function HiddenGetParamsProvider({ children }: { children: ReactNode }) {
	const location = useLocation()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const [nextGetParam, setNextGetParam] = useState<string>()

	// When we have get params
	useEffect(() => {
		// Hide 'next' param from and store in context
		let nextGetParam = searchParams.get("next")?.replace(/^\/|$/g, "")
		if (nextGetParam) {
			setNextGetParam(nextGetParam)
			navigate(location.pathname)
		}
	}, [searchParams, location, navigate])

	return (
		<HiddenGetParamsContext.Provider
			value={{
				next: nextGetParam,
			}}
		>
			{useMemo(() => children, [children])}
		</HiddenGetParamsContext.Provider>
	)
}
