import { ReactNode } from "react"
import { Provider, useSelector, useDispatch } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

// Store
import { store, persistor } from "./store"

interface StateProviderProps {
	children: ReactNode
}

/**
 * StateProvider
 * @param param0
 * @returns
 */
export const StateProvider = function ({ children }: StateProviderProps) {
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>{children}</PersistGate>
		</Provider>
	)
}

// Export
export { useSelector, useDispatch }
