import { useEffect } from "react"
import { throttle } from "@/lib/throttle"
import { isDevelopment } from "@/utils/helpers"

// Dates
import { ms } from "@/lib/dates"

const time = ms("30 mins")

export const useOnInactivity = (callback: () => void) => {
	useEffect(() => {
		if (isDevelopment()) {
			return
		}

		let timeout: NodeJS.Timeout
		const resetTimer = throttle(() => {
			clearTimeout(timeout)
			timeout = setTimeout(callback, time)
		}, 1000)
		resetTimer()

		const events = [
			"mousedown",
			"mousemove",
			"keypress",
			"scroll",
			"touchstart",
		]
		events.forEach((name) => document.addEventListener(name, resetTimer))

		return () => {
			events.forEach((name) =>
				document.removeEventListener(name, resetTimer),
			)
			clearTimeout(timeout)
		}
	}, [callback])
}
