// React
import { ReactNode } from "react"

// UI
import { HeaderNoAuth } from "@/components/navigation/HeaderNoAuth"

/**
 * NoAuthLayout
 * Renders the children without any authentication or authorization checks
 * Displays the branded header
 *
 * @param param0
 * @returns
 */
export function NoAuthLayout({ children }: { children: ReactNode }) {
	return (
		<>
			<HeaderNoAuth />
			<div className="flex h-full items-center justify-center bg-white px-4">
				<div>{children}</div>
			</div>
		</>
	)
}
