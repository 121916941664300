// Hooks
import { useCurrentFinanceProject } from "../hooks/useCurrentFinanceProject"

// Analytics
import { sendEvent } from "@/lib/analytics"

// Icons
import { FiDownload } from "@/lib/icons"

// SEO meta
import { Helmet } from "@/lib/seo"

// Router
import { useNavigate } from "@/lib/router"
import { Routes } from "@/constants/routes"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// UI
import { classNames } from "@/lib/classnames"
import { ProjectSelector } from "@/components/ProjectSelector"
import { GraphLabel, GraphLabels } from "@/components/graphs/GraphLabels"
import { Heading } from "@/components/Typography"
import { Card } from "@/components/Card"
import { SolarInterestTariffGraph } from "@/components/graphs/types/area/SolarInterestTariffGraph"
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import { FinanceProjectSubMenuTabs } from "../components/FinanceProjectSubMenuTabs"

// Template
import { PageTemplate } from "@/templates/PageTemplate"

// REST queries
import {
	useApiV1ComparisonProjectEndexTariffsList,
	useApiV1ComparisonProjectEndexPriceRetrieve,
} from "@/api/rest/generated/api/api"

// Graphs
import { FinanceGraphSolarInterestTariff } from "../components/FinanceGraphSolarInterestTariff"

// Types
import { EndexTariff } from "@/api/rest/generated/@types"

/**
 * FinanceSolarInterestTariff
 * @returns
 */
export function FinanceSolarInterestTariff() {
	const currentProject = useCurrentFinanceProject().data.project
	const projectId = Number(currentProject?.id) || 1 // TODO: Pick a default
	const navigate = useNavigate()

	// Translate
	const t = useTrans("finance")
	const { formatNumber } = useLang()

	// Rest Query
	const { data } = useApiV1ComparisonProjectEndexTariffsList(projectId)
	const { data: dataEndexPrice } =
		useApiV1ComparisonProjectEndexPriceRetrieve(projectId)

	return (
		<>
			<Helmet>
				<title>
					{t(
						"finance:finance.solar_interest_tariff_with_project.title",
						{
							projectName: currentProject?.name,
						},
					)}
				</title>
			</Helmet>
			<PageTemplate
				title={t(
					"finance:finance.solar_interest_tariff_with_project.title",
					{
						projectName: currentProject?.name,
					},
				)}
				topRightComponent={
					<ProjectSelector
						projectId={projectId}
						onChange={(id) => {
							sendEvent("finance", "performance_project_filter", {
								label: id,
							})
							navigate(
								`${Routes.FinanceSolarInterestTariff}/${id}`,
							)
						}}
					/>
				}
				backHref={Routes.FinanceProjects}
				tabs={<FinanceProjectSubMenuTabs />}
			>
				<div className="flex flex-col gap-8 md:flex-row">
					<Card className="md:w-1/2">
						<Heading as="h3" styleAs="h6" className="mr-2 lg:mr-0">
							{t(
								"finance:finance.solar_interest_tariff.block.endex_tariffs.title",
							)}
						</Heading>

						<SolarInterestTariffGraph>
							<SolarInterestTariffGraph.Content>
								<FinanceGraphSolarInterestTariff />
							</SolarInterestTariffGraph.Content>
							<SolarInterestTariffGraph.Footer>
								<GraphLabels className="p-3 px-4 sm:p-5 sm:px-6">
									<GraphLabel
										type="line"
										variant="primary"
										title={t(
											"finance:finance.solar_interest_tariff.block.endex_tariffs",
										)}
									/>
									<GraphLabel
										type="bar"
										variant="default"
										title={t(
											"finance:finance.solar_interest_tariff.block.average_tariffs",
										)}
									/>
								</GraphLabels>
							</SolarInterestTariffGraph.Footer>
						</SolarInterestTariffGraph>
					</Card>

					<div className="flex h-[600px] flex-col gap-8 md:w-1/2">
						<Card>
							<Heading
								as="h3"
								styleAs="h6"
								className="mr-2 lg:mr-0"
							>
								Endex 12-0-12
							</Heading>

							{dataEndexPrice?.price ? (
								<>
									{`${dataEndexPrice?.start} - ${dataEndexPrice?.end}`}
									<div>
										{`€${formatNumber(
											dataEndexPrice?.price / 1000,
											{
												minimumFractionDigits: 6,
												maximumFractionDigits: 6,
											},
										)} per kWh`}
									</div>
								</>
							) : null}
						</Card>
						<Card className="h-full overflow-y-scroll">
							<Heading
								as="h3"
								styleAs="h6"
								className="mr-2 lg:mr-0"
							>
								{t(
									"finance:finance.solar_interest_tariff.block.solar-tariffs.title",
								)}
							</Heading>
							<Table>
								<TableHead>
									<tr role="row">
										<TableHeading as="th" variant="static">
											{t(
												"finance:finance.solar_interest_tariff.block.solar-tariffs.table.headers.period",
											)}
										</TableHeading>
										<TableHeading as="th" variant="static">
											{t(
												"finance:finance.solar_interest_tariff.block.solar-tariffs.table.headers.fixed_surcharge",
											)}
										</TableHeading>
										<TableHeading as="th" variant="static">
											{t(
												"finance:finance.solar_interest_tariff.block.solar-tariffs.table.headers.average_endex_tariff",
											)}
											<div className="hidden">
												{t(
													"finance:finance.solar_interest_tariff.block.solar-tariffs.table.headers.average_endex_tariff.tooltip",
												)}
											</div>
										</TableHeading>
										<TableHeading
											as="th"
											variant="static"
											className="w-20"
										>
											{t(
												"finance:finance.solar_interest_tariff.block.solar-tariffs.table.headers.export_link",
											)}
										</TableHeading>
									</tr>
								</TableHead>
								{data && data.length > 0 && (
									<TableBody role="rowgroup">
										{data.map(
											(
												tariff: EndexTariff,
												index: number,
											) => {
												return (
													<TableRowCell
														key={index}
														isOdd={index % 2 === 0}
														role="row"
													>
														<TableDataCell className="break-word whitespace-pre-wrap">
															{`${tariff.start} - ${tariff.end}`}
														</TableDataCell>
														<TableDataCell>
															{t(
																"finance:finance.solar_interest_tariff.graph.tooltip.average_tariffs.value",
																{
																	price: tariff.fixed_surcharge,
																},
															)}
														</TableDataCell>
														<TableDataCell>
															{t(
																"finance:finance.solar_interest_tariff.graph.tooltip.average_tariffs.value",
																{
																	price: parseFloat(
																		tariff.average_endex_tariff,
																	).toFixed(
																		2,
																	),
																},
															)}
														</TableDataCell>
														<TableDataCell className="w-20">
															<a
																href={
																	tariff.export_link
																}
																className={classNames(
																	"text-gray-500 hover:text-gray-700",
																	"group px-4",
																	"whitespace-nowrap text-sm font-medium",
																)}
															>
																<FiDownload />
															</a>
														</TableDataCell>
													</TableRowCell>
												)
											},
										)}
									</TableBody>
								)}
							</Table>
						</Card>
					</div>
				</div>
				<Card className="mt-8">
					<Heading as="h3" styleAs="h6" className="mr-2 lg:mr-0">
						{t(
							"finance:finance.solar_interest_tariff.block.info.title",
						)}
					</Heading>
					<p className="whitespace-break-spaces">
						{t(
							"finance:finance.solar_interest_tariff.block.info.copy",
						)}
					</p>
				</Card>
			</PageTemplate>
		</>
	)
}
