import { ReactNode } from "react"
import { Helmet } from "@/lib/seo"
import { useTrans } from "@/i18n"
import { PageTemplate, PageTemplateLink } from "@/templates/PageTemplate"
import { useCurrentProject } from "./hooks"

// Pages
import { Routes } from "@/constants/routes"
import { ProjectNotFound } from "./ProjectNotFound"

import { ProjectStateEnum } from "@/api/graphql"

// Feature flags
import { useFeatureFlags } from "@/context/user"

export const ProjectLayout = ({ children }: { children: ReactNode }) => {
	const { getFeatureFlagValue } = useFeatureFlags()
	const { data } = useCurrentProject()
	const t = useTrans("project")

	if (data?.project === null) {
		return <ProjectNotFound />
	}

	return (
		<>
			<Helmet>
				<title>{data?.project?.name}</title>
			</Helmet>
			<PageTemplate
				title={data?.project?.name ?? ""}
				backHref={Routes.InvestmentsProjectsDashboard}
				tabs={
					<>
						<PageTemplateLink
							to={`${Routes.InvestmentsProjects}/${data.project.id}/dashboard`}
							end
							data-testid="project.dashboard"
							name={t("project.dashboard.title")}
							analyticsId="project.dashboard"
						/>
						<PageTemplateLink
							to={`${Routes.InvestmentsProjects}/${data.project.id}/revenue`}
							data-testid="project.revenue"
							name={t("project.revenue.title")}
							analyticsId="project.revenue"
						/>

						{/** Show production when project has Production data */}
						{data?.project.state ===
						ProjectStateEnum.InProduction ? (
							<PageTemplateLink
								to={`${Routes.InvestmentsProjects}/${data.project.id}/data`}
								data-testid="project.data"
								name={t("project.data.title")}
								analyticsId="project.data"
							/>
						) : null}

						<PageTemplateLink
							to={`${Routes.InvestmentsProjects}/${data.project.id}/notifications`}
							data-testid="project.notifications"
							name={t("project.notifications.title")}
							analyticsId="project.notifications"
						/>
						{getFeatureFlagValue(
							"FEATURE_ENABLE_PROJECT_TIMELINE",
						) === true && (
							<PageTemplateLink
								to={`${Routes.InvestmentsProjects}/${data.project.id}/communications`}
								data-testid="project.communications"
								name={t("project.communications.title")}
								analyticsId="project.communications"
							/>
						)}

						<PageTemplateLink
							to={`${Routes.InvestmentsProjects}/${data.project.id}/documentation`}
							data-testid="project.documentation"
							name={t("project.documentation.title")}
							analyticsId="project.documentation"
						/>
					</>
				}
			>
				{children}
			</PageTemplate>
		</>
	)
}
