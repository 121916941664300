/**
 * Luxon
 */
import { DateTime, Duration, DateTimeUnit, Settings } from "luxon"
import ms from "ms"

// Vars
export const DEFAULT_TIMEZONE = "Europe/Amsterdam"
export const DEFAULT_LOCALE = "nl"

// Set default timezone and locale
Settings.defaultZone = DEFAULT_TIMEZONE
Settings.defaultLocale = DEFAULT_LOCALE

// TODO: Clean up this file!
export const dateToLocaleString = (
	dateText: string,
	options: Intl.DateTimeFormatOptions = localeStringOptions,
) => DateTime.fromISO(dateText).toLocaleString(options)

export const localeStringOptionsWithYear: Intl.DateTimeFormatOptions = {
	day: "numeric",
	month: "short",
	weekday: "short",
	year: "numeric",
}

export const localeStringOptions: Intl.DateTimeFormatOptions = {
	day: "numeric",
	month: "short",
	weekday: "short",
}

export const today = DateTime.local()

// Transforms "EUROPE_AMSTERDAM" into "Europe/Amsterdam"
export function transformTimezoneConstantToJSTimezone(
	inputString: string | undefined,
): string {
	if (inputString === undefined) {
		return DEFAULT_TIMEZONE
	} else {
		return inputString
			.split("_")
			.map(
				(word) =>
					word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
			)
			.join("/")
	}
}

// Allow to set locale
export function setLocale(locale: string) {
	Settings.defaultLocale = locale
}

// Export default from Luxon
export { DateTime, Duration, ms }

// Types
export type { DateTimeUnit }
