import { ReactNode } from "react"

// Constants
import { UserRoles } from "@/constants/constants"

// Contexts
import { useCurrentUserRoles } from "@/context/user"
import { useAuth, AuthStateStatusEnum } from "@/context/auth"

// Pages
import { NotFound } from "@/pages/NotFound"

/**
 * ConditionalRoute
 *
 * Renders conditionally either AUTH or UNAUTH pages
 *
 * @param param0
 * @returns
 */
interface ConditionalRouteProps {
	roles?: Array<UserRoles>
	auth: ReactNode
	unAuth: ReactNode
}
export const ConditionalRoute = ({
	roles,
	auth = <></>,
	unAuth = <></>,
}: ConditionalRouteProps) => {
	const authContext = useAuth()
	const { hasOneOfRoles } = useCurrentUserRoles()

	// Loggout out pages
	if (authContext.status === AuthStateStatusEnum.LOGGED_OUT) {
		return <>{unAuth}</>
	}

	// Auth page with correct roles
	if (
		authContext.status === AuthStateStatusEnum.LOGGED_IN &&
		roles !== undefined &&
		hasOneOfRoles(...roles)
	) {
		return <>{auth}</>
	}

	// Auth page without required roles
	if (
		authContext.status === AuthStateStatusEnum.LOGGED_IN &&
		roles === undefined
	) {
		return <>{auth}</>
	}

	// Default to not found
	return <NotFound />
}
