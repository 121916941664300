import { ReactNode } from "react"
import { Helmet } from "@/lib/seo"
import { PageTemplate, PageTemplateLink } from "@/templates/PageTemplate"

// Translations
import { useTrans } from "@/i18n"

// Pages
import { Routes } from "@/constants/routes"

// Feature flags
import {
	useFeatureFlags,
	useCurrentUserIdentityType,
	useCurrentUserKyc,
} from "@/context/user"

// GraphQL
import {
	CoreInvestmentTestExperienceChoices,
	RiskKycStateChoices,
	UsersUserProfileIdentityTypeChoices,
} from "@/api/graphql"

// Context
import { useInvestmentTests } from "@/context/investmentTests"

/**
 * InvestorLayout
 * @param param0
 * @returns
 */
export function InvestorLayout({ children }: { children: ReactNode }) {
	const t = useTrans("investor")
	const { getFeatureFlagValue } = useFeatureFlags()
	const identity_type: UsersUserProfileIdentityTypeChoices =
		useCurrentUserIdentityType()
	const userKyc = useCurrentUserKyc()

	// Context
	const {
		investorProfileExperience,
		investorTestQuestions,
		investorTestRisk,
	} = useInvestmentTests()

	// What nav items do we show?
	const showInvestorIdentity =
		identity_type !== UsersUserProfileIdentityTypeChoices.Business &&
		userKyc.state !== RiskKycStateChoices.Uninitiated

	// Return
	return (
		<>
			<Helmet>
				<title>{t("investor:investor.title")}</title>
			</Helmet>
			<PageTemplate
				title={t("investor:investor.title")}
				tabs={
					<>
						<PageTemplateLink
							to={Routes.InvestorProfile}
							data-testid="investor.profile"
							name={t("investor:investor.profile.title")}
							analyticsId="investor.profile"
							badgeNumber={investorProfileExperience ? 0 : 1}
							badge={{
								type: Boolean(investorProfileExperience)
									? "verified"
									: undefined,
							}}
						/>

						{/** Show if user is NOVICE */}
						{investorProfileExperience?.experience ===
							CoreInvestmentTestExperienceChoices.Novice && (
							<>
								<PageTemplateLink
									to={Routes.InvestorTest}
									data-testid="investor.investor_test"
									name={t("investor:investor.test.title")}
									analyticsId="investor.investor_test"
									badgeNumber={investorTestQuestions ? 0 : 1}
									badge={{
										type: Boolean(investorTestQuestions)
											? "verified"
											: undefined,
									}}
								/>
								<PageTemplateLink
									to={Routes.InvestorRisk}
									data-testid="investor.investor_risk"
									name={t("investor:investor.risk.title")}
									analyticsId="investor.investor_risk"
									badgeNumber={investorTestRisk ? 0 : 1}
									badge={{
										type: Boolean(investorTestRisk)
											? "verified"
											: undefined,
									}}
								/>
							</>
						)}

						{getFeatureFlagValue(
							"FEATURE_ENABLE_INVESTOR_FUND_ORIGINS",
						) === true ? (
							<PageTemplateLink
								to={Routes.InvestorFunds}
								data-testid="investor.funds"
								name={t("investor:investor.funds.title")}
								analyticsId="investor.funds"
							/>
						) : null}

						{showInvestorIdentity === true ? (
							<PageTemplateLink
								to={Routes.InvestorIdentity}
								data-testid="investor.indentity"
								name={t("investor:investor.identity.title")}
								analyticsId="investor.identity"
								badgeNumber={
									userKyc.state !==
									RiskKycStateChoices.VerificationRequested
										? 0
										: 1
								}
								badge={{
									type:
										userKyc.state !==
										RiskKycStateChoices.VerificationRequested
											? "verified"
											: undefined,
								}}
							/>
						) : null}
					</>
				}
			>
				{children}
			</PageTemplate>
		</>
	)
}
