import { useParams } from "@/lib/router"
import { useFinanceProjectIndexQuery } from "@/api/graphql"

// This specifically calls Finance projects
export function useCurrentFinanceProject() {
	const params = useParams()

	const id = params.id as string

	const { data, ...rest } = useFinanceProjectIndexQuery({ id })

	// Customize the data to fetch single project
	return {
		...rest,
		data: {
			...data,
			project: data?.me?.finance_projects?.results?.[0] ?? null,
		},
	}
}
