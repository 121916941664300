// UI
import { Card } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { Button } from "@/components/Button"
import { classNames } from "@/lib/classnames"

// Translations
import { useTrans } from "@/i18n"

// Environment variables
import { EXTERNAL_PLATFORM_ZONHUB_INVESTMENT } from "@/lib/env"

/**
 * InvestmentOppertunityBanner
 * @returns
 */
export function InvestmentOppertunityBanner({
	className = "",
}: {
	className?: any
}) {
	const t = useTrans("investments")

	return (
		<Card className={classNames("w-full bg-yellow-50", className)}>
			<Heading as="h2" styleAs="h5" className="mb-3 sm:truncate">
				{t("investments:investments.banner.oppertunities.title")}
			</Heading>
			<div className="mb-4">
				<p className="text-sm text-gray-500">
					{t("investments:investments.banner.oppertunities.copy")}
				</p>
			</div>
			<Button
				className="inline-block"
				onClick={() => {
					window.location.href = EXTERNAL_PLATFORM_ZONHUB_INVESTMENT
				}}
			>
				{t("investments:investments.banner.oppertunities.cta")}
			</Button>
		</Card>
	)
}
