import { Helmet } from "@/lib/seo"
import { CompanyDetails } from "@/components/forms/CompanyDetails"
import { useTrans } from "@/i18n"

export const ProfileCompanyDetails = () => {
	const t = useTrans("profile")

	return (
		<>
			<Helmet>
				<title>{t("profile:profile.company_details.title")}</title>
			</Helmet>
			<CompanyDetails />
		</>
	)
}
