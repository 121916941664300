import { useParams } from "@/lib/router"
import { useProjectIndexQuery } from "@/api/graphql"

// This specifically calls Investment projects
export function useCurrentProject() {
	const params = useParams()

	const id = params.id as string

	const { data, ...rest } = useProjectIndexQuery({ id })

	// Customize the data to fetch single project
	return {
		...rest,
		data: {
			...data,
			project: data?.me?.investment_projects?.results?.at(0) ?? null,
		},
	}
}
