// React
import { Suspense, useRef, useMemo } from "react"

// SEO
import { Helmet } from "@/lib/seo"

// Router
import { useParams } from "@/lib/router"

// Translate
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Dates
import { DateTime } from "@/lib/dates"

// Pdf
import { generatePDF, getPdfFileName, PDFViewWrapper } from "@/lib/pdf"

// General UI
import { Card, CardWrapper } from "@/components/Card"
import { ErrorBoundaryWithErrorState } from "@/components/errors/ErrorBoundary"
import { GraphLabels } from "@/components/graphs/GraphLabels"
import { Heading } from "@/components/Typography"
import { ProductionGraph } from "@/components/graphs/types/bar/ProductionGraph"
import { LoadingScreen } from "@/screens/LoadingScreens"

// Specific UI
import { InvestmentsGraphProduction } from "../../components/InvestmentsGraphProduction"
import { PayoutsTableWeb } from "../_components/PayoutsTableWeb"
import { FinancePerformanceContext } from "@/pages/finance/pages/Performance"
import { ProjectMonthlyProductionTable } from "./_components/ProjectMonthlyProductionTable"
import { IntroHeader } from "./_components/IntroHeader"

// GraphQL
import { useIsFetching } from "@/lib/query"
import { ProjectTypeEnum, useMonthlyOverviewQuery } from "@/api/graphql"

/**
 * MonthlyOverview
 * @returns
 */
export function MonthlyOverview() {
	// Translate
	const t = useTrans(["investments", "dashboard"])

	// Router
	const params = useParams()

	// Calculate end date with fallback to current date
	const endTime = useMemo(
		() =>
			params.date
				? DateTime.fromFormat(String(params.date), "yyyy-MM").endOf(
						"month",
				  )
				: DateTime.now().minus({ months: 1 }).endOf("month"), // Start from previous month
		[params.date],
	)

	// Queries
	const { data } = useMonthlyOverviewQuery()
	const isFetching = useIsFetching()

	// Data
	const currentShareValueForSolarShares =
		Number(
			data?.me?.end?.total_investment_value_per_project_type?.find(
				(type) => type?.project_type === ProjectTypeEnum.SolarShares,
			)?.share_value,
		) || 0
	const currentShareValueForSolarBonds = (
		data?.me?.end?.total_investment_value_per_project_type || []
	).reduce((accumulator, type) => {
		let shareValue = 0
		if (
			type?.project_type === ProjectTypeEnum.SolarBonds ||
			type?.project_type === ProjectTypeEnum.Portfolio
		) {
			shareValue = Number(type?.share_value)
		}

		return accumulator + shareValue
	}, 0)

	// PDF
	const pdfViewRef = useRef(null)
	const PDF_TITLE = t("investments:investments.monthly_overview.title", {
		date: endTime.toLocaleString({
			month: "long",
			year: "numeric",
		}),
	})
	const PDF_VIEW_WIDTH = 1152 // // Width that the table renders optimally at
	async function handlePdfDownload() {
		if (pdfViewRef.current) {
			return generatePDF(pdfViewRef.current, {
				filename: getPdfFileName(PDF_TITLE),
				overrideWidth: PDF_VIEW_WIDTH,
				compression: "FAST",
			})
		}
	}

	// Template
	return (
		<>
			<Helmet>
				<title>
					{t("investments:investments.monthly_overview.title", {
						date: endTime.toLocaleString({
							month: "long",
							year: "numeric",
						}),
					})}
				</title>
			</Helmet>

			{/** Intro header */}
			<IntroHeader
				fullName={data?.me?.full_name}
				endTime={endTime}
				handlePdfDownload={handlePdfDownload}
				displayDownloadButton={isFetching === 0}
				totalProjectCount={
					data?.me?.investment_projects?.totalCount || 0
				}
				totalInvestmentValue={
					data?.me?.end?.total_investment_value || "0"
				}
			/>

			{/** Invisible Print view */}
			<PDFViewWrapper
				ref={pdfViewRef}
				title={PDF_TITLE}
				width={PDF_VIEW_WIDTH}
			>
				<IntroHeader
					displayDownloadButton={false}
					fullName={data?.me?.full_name}
					endTime={endTime}
					totalProjectCount={
						data?.me?.investment_projects?.totalCount || 0
					}
					totalInvestmentValue={
						data?.me?.end?.total_investment_value || "0"
					}
				/>
				<OverviewComponents
					currentShareValueForSolarShares={
						currentShareValueForSolarShares
					}
					currentShareValueForSolarBonds={
						currentShareValueForSolarBonds
					}
				/>
			</PDFViewWrapper>

			{/** Visible webview */}
			<OverviewComponents
				currentShareValueForSolarShares={
					currentShareValueForSolarShares
				}
				currentShareValueForSolarBonds={currentShareValueForSolarBonds}
			/>
		</>
	)
}

/**
 * OverviewComponents
 * @returns
 */
function OverviewComponents({
	currentShareValueForSolarShares,
	currentShareValueForSolarBonds,
}: {
	currentShareValueForSolarShares: number
	currentShareValueForSolarBonds: number
}) {
	// Translate
	const { formatCurrency } = useLang()
	const t = useTrans("project")

	// Router
	const params = useParams()

	// Calculate start and end dates with fallback to current date
	const startTime = useMemo(
		() =>
			params.date
				? DateTime.fromFormat(String(params.date), "yyyy-MM").startOf(
						"month",
				  )
				: DateTime.now().minus({ months: 1 }).startOf("month"),
		[params.date],
	)
	const endTime = useMemo(
		() =>
			params.date
				? DateTime.fromFormat(String(params.date), "yyyy-MM").endOf(
						"month",
				  )
				: DateTime.now().endOf("month"),
		[params.date],
	)

	// Template
	return (
		<>
			<CardWrapper className="mt-8">
				<Card>
					<Heading as="h2" styleAs="h5" className="mb-4">
						{t(
							"investments:investments.monthly_overview.block.shares.title",
						)}
					</Heading>

					{/** Share value */}
					<div className="col-span-1 rounded-md bg-gray-50 px-4 py-3">
						<dt className="text-sm font-medium text-gray-500">
							{t(
								"investments:investments.monthly_overview.block.shares.value",
							)}
						</dt>
						<dd className="mt-1 text-sm text-gray-900">
							{formatCurrency(currentShareValueForSolarShares)}
						</dd>
					</div>
					<ErrorBoundaryWithErrorState>
						<FinancePerformanceContext.Provider
							value={{
								selectedDateRangeStart: startTime
									.plus({ days: 1 })
									.toJSDate(),
								setSelectedDateRangeStart: () => {},
								selectedDateRangeEnd: endTime.toJSDate(),
								setSelectedDateRangeEnd: () => {},
								graphInterval: "day",
							}}
						>
							<ProductionGraph>
								<ProductionGraph.Actions>
									{null}
								</ProductionGraph.Actions>
								<ProductionGraph.Content>
									<InvestmentsGraphProduction />
								</ProductionGraph.Content>
								<ProductionGraph.Footer>
									<GraphLabels className="p-3 px-4 sm:p-5 sm:px-6">
										<GraphLabels.TotalProduction />
										<GraphLabels.ExpectedProduction />
									</GraphLabels>
								</ProductionGraph.Footer>
							</ProductionGraph>
						</FinancePerformanceContext.Provider>
					</ErrorBoundaryWithErrorState>
				</Card>
			</CardWrapper>

			<CardWrapper className="mt-8">
				<Card>
					<Heading as="h2" styleAs="h5" className="mb-4">
						{t(
							"investments:investments.monthly_overview.block.bonds.title",
						)}
					</Heading>

					{/** Share value */}
					<div className="col-span-1 rounded-md bg-gray-50 px-4 py-3">
						<dt className="text-sm font-medium text-gray-500">
							{t(
								"investments:investments.monthly_overview.block.bonds.value",
							)}
						</dt>
						<dd className="mt-1 text-sm text-gray-900">
							{formatCurrency(currentShareValueForSolarBonds)}
						</dd>
					</div>
				</Card>
			</CardWrapper>

			<CardWrapper className="mt-8">
				<Card>
					<Heading as="h2" styleAs="h5" className="mb-4">
						{t("project:project.revenue.all.title")}
					</Heading>

					<Suspense fallback={<LoadingScreen />}>
						<PayoutsTableWeb
							showPagination={false}
							startTime={startTime}
							endTime={endTime}
						/>
					</Suspense>
				</Card>
			</CardWrapper>

			<CardWrapper className="mt-8">
				<Card>
					<Heading as="h2" styleAs="h5" className="mb-4">
						{t(
							"investments:investments.monthly_overview.block.project-production-table.title",
							{
								month: startTime.toLocaleString({
									month: "long",
								}),
							},
						)}
					</Heading>

					<Suspense fallback={<LoadingScreen />}>
						<ProjectMonthlyProductionTable
							startTime={startTime}
							endTime={endTime}
						/>
					</Suspense>
				</Card>
			</CardWrapper>
		</>
	)
}
