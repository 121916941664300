import { ReactNode } from "react"
import { Helmet } from "@/lib/seo"

// UI
import { classNames } from "@/lib/classnames"
import { PageTemplate, PageTemplateLink } from "@/templates/PageTemplate"

// Context
import { useFeatureFlags, useCurrentUserIdentityType } from "@/context/user"

// Error handling
import { ErrorBoundary } from "@/components/errors/ErrorBoundary"
import { ErrorState } from "@/components/errors/ErrorState"

// Translations
import { useTrans } from "@/i18n"

// Pages
import { Routes } from "@/constants/routes"
import { AppSettings } from "@/pages/profile/pages/AppSettings"
import { BankDetails } from "@/components/forms/BankDetails"
import { PersonalDetails } from "@/components/forms/PersonalDetails"

// GraphQL
import { UsersUserProfileIdentityTypeChoices } from "@/api/graphql"

// Environment variables
import { AUTH_MFA_URL } from "@/lib/env"

export function ProfileLayout({ children }: { children: ReactNode }) {
	const t = useTrans("profile")
	const { getFeatureFlagValue } = useFeatureFlags()
	const identity_type: UsersUserProfileIdentityTypeChoices =
		useCurrentUserIdentityType()

	return (
		<>
			<Helmet>
				<title>{t("profile:profile.my_profile.title")}</title>
			</Helmet>
			<PageTemplate
				title={t("profile:profile.header")}
				tabs={
					<>
						<PageTemplateLink
							to={Routes.SettingsProfilePersonalDetails}
							data-testid="profile.personal_details"
							name={t("profile:profile.personal_details.title")}
							analyticsId="profile.personal_details"
						/>

						{/** Show tab only if user has organisation account */}
						{identity_type ===
							UsersUserProfileIdentityTypeChoices.Business && (
							<PageTemplateLink
								to={Routes.SettingsProfileCompanyDetails}
								data-testid="profile.company_details"
								name={t(
									"profile:profile.company_details.title",
								)}
								analyticsId="profile.company_details"
							/>
						)}
						<PageTemplateLink
							to={Routes.SettingsProfileBankDetails}
							data-testid="profile.bank_details"
							name={t("profile:profile.bank_details.title")}
							analyticsId="profile.bank_details"
						/>
						<PageTemplateLink
							to={Routes.SettingsProfileChangePassword}
							data-testid="profile.change_password"
							name={t("profile:profile.change_password.title")}
							analyticsId="profile.change_password"
						/>
						<a
							href={AUTH_MFA_URL}
							className={classNames(
								"text-gray-500 hover:text-gray-700",
								"border-b border-gray-300",
								"group px-4",
								"whitespace-nowrap text-sm font-medium",
							)}
						>
							<span
								className={classNames(
									"flex h-full translate-y-px transform gap-1 truncate border-b-2 py-3 lg:py-4",
									"border-transparent group-hover:border-b-2 group-hover:border-gray-300",
								)}
							>
								{t("profile.security.title")}
							</span>
						</a>
						<PageTemplateLink
							to={Routes.SettingsProfileNotifications}
							data-testid="profile.notifications"
							name={t("profile:profile.notifications.title")}
							analyticsId="profile.notifications"
						/>

						<PageTemplateLink
							to={Routes.AppSettings}
							data-testid="settings.app"
							name={t("profile:profile.settings.app")}
							analyticsId="settings.app"
						/>

						{getFeatureFlagValue("ENABLE_MFA_SECURITY_PAGE") ===
							true && (
							<PageTemplateLink
								to={Routes.SettingsProfileSecurity}
								data-testid="profile.security"
								name={t("profile:profile.security.title")}
								analyticsId="profile.security"
								end={false}
							/>
						)}
					</>
				}
			>
				{children}
			</PageTemplate>
		</>
	)
}

export const ProfilePersonalData = () => {
	const t = useTrans("profile")

	return (
		<>
			<Helmet>
				<title>{t("profile:profile.personal_details.title")}</title>
			</Helmet>
			<div>
				<ErrorBoundary fallback={<ErrorState />}>
					<PersonalDetails />
				</ErrorBoundary>
			</div>
		</>
	)
}

export const SettingsApp = () => {
	const t = useTrans("profile")

	return (
		<>
			<Helmet>
				<title>{t("profile:profile.settings.app")}</title>
			</Helmet>
			<div>
				<ErrorBoundary fallback={<ErrorState />}>
					<AppSettings />
				</ErrorBoundary>
			</div>
		</>
	)
}

export const ProfileBankDetails = () => {
	const t = useTrans("profile")

	return (
		<>
			<Helmet>
				<title>{t("profile:profile.bank_details.title")}</title>
			</Helmet>
			<div>
				<ErrorBoundary fallback={<ErrorState />}>
					<BankDetails />
				</ErrorBoundary>
			</div>
		</>
	)
}

export const ProfileSecurityComponentLayout = ({
	children,
}: {
	children: ReactNode
}) => {
	const t = useTrans("profile")

	return (
		<>
			<Helmet>
				<title>{t("profile:profile.security.title")}</title>
			</Helmet>
			<ErrorBoundary fallback={<ErrorState />}>{children}</ErrorBoundary>
		</>
	)
}
