// React
import { ReactNode, useState, useMemo, createContext, useCallback } from "react"

// Create redirect context
export const RedirectContext = createContext<{
	isRedirecting: boolean
	setIsRedirecting: (value: boolean) => void
	redirect: (url: string) => void
}>({
	isRedirecting: false,
	setIsRedirecting: () => {},
	redirect: () => {},
})

/**
 * RedirectProvider
 * @param param0
 * @returns
 */
export function RedirectProvider({ children }: { children: ReactNode }) {
	const [isRedirecting, setIsRedirecting] = useState(false)

	const redirect = useCallback((url: string) => {
		if (typeof window !== "undefined") {
			setIsRedirecting(true)
			window.location.href = url
		}
	}, [])

	const value = useMemo(
		() => ({
			isRedirecting,
			setIsRedirecting,
			redirect,
		}),
		[redirect],
	)

	return (
		<RedirectContext.Provider value={value}>
			{children}
		</RedirectContext.Provider>
	)
}
