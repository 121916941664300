// React
import { useMemo, ReactNode, ComponentType, createContext } from "react"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Utils
import { getShareNumberRange } from "@/utils/helpers"

// GraphQL
import { useCurrentFinancialOverviewQuery, ProjectType } from "@/api/graphql"

// UI
import { CardBody } from "@/components/Card"
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import { Heading } from "@/components/Typography"
import { Tooltip } from "@/components/Tooltip"

// Hooks
import { useTableMetaData } from "@/hooks/useTableMetaData"

// Utils
import { isWholeNumber } from "@/lib/math"

// Environment variables
import { EXTERNAL_PLATFORM_ZONHUB_INVESTMENT_CERTIFICATE } from "@/lib/env"

// Icons
import { FiDownload } from "@/lib/icons"

// Types
export type FinancialOverviewContextType =
	| { type: "custom"; from: string; to: string }
	| { type: "year"; year: string }

export const FinancialOverviewContext =
	createContext<FinancialOverviewContextType>(null!)

// Types
type Data = ProjectType & {
	shareNumbers: string
	certificateUrl?: string
}

type Columns<Data> = Array<{
	title: string
	sort?: string
	accessor: (data: Data) => ReactNode
	Footer?: ComponentType<{ rows: Array<Data> }>
	className?: string
	component?: (props: { payment: Data }) => ReactNode
}>

/**
 * FinancialOverviewTable
 * @returns
 */
export function FinancialOverviewTable() {
	// Translations
	const { formatCurrency } = useLang()
	const t = useTrans(["investments", "dashboard", "project"])

	const { setSort, sort } = useTableMetaData()
	const { data, isPreviousData } = useCurrentFinancialOverviewQuery(
		undefined,
		{
			keepPreviousData: false,
		},
	)

	const rows = useMemo(() => {
		if (!data?.me?.investment_projects) {
			return []
		}
		return data.me.investment_projects?.results?.map((project) => {
			return {
				...project,
				shareNumbers:
					getShareNumberRange(
						project?.investor_shares_value_stats?.shares?.map(
							(share) => Number(share?.share_number),
						),
						t,
					) ?? undefined,
				certificateUrl: `${EXTERNAL_PLATFORM_ZONHUB_INVESTMENT_CERTIFICATE}${project?.id}`,
			}
		})
	}, [data?.me?.investment_projects])

	const columns: Columns<Data> = [
		{
			title: t(
				"investments:investments.fiscal.table_heading.project_name",
			),
			accessor: (data) => data?.name,
		},
		{
			title: t("dashboard:financial_overview_table.heading.total_shares"),
			accessor: (data) =>
				data?.investor_shares_value_stats?.total_shares
					? isWholeNumber(
							data.investor_shares_value_stats?.total_shares,
					  )
						? data.investor_shares_value_stats?.total_shares
						: Number(
								data.investor_shares_value_stats
									?.total_shares ?? 0,
						  ).toFixed(2)
					: "-",
		},
		{
			title: t(
				"dashboard:financial_overview_table.heading.original_value",
			),
			accessor: (data) =>
				formatCurrency(
					Number(
						data.investor_shares_value_stats
							?.total_nominal_investment,
					) ?? 0,
				),
		},
		{
			title: t("project:project.dashboard.hero.share_value.title"),
			accessor: (data) =>
				data.investor_shares_value_stats?.total_investment_value
					? formatCurrency(
							parseFloat(
								data.investor_shares_value_stats
									?.total_investment_value,
							) ?? 0,
					  )
					: "-",
		},
		{
			title: t(
				"investments:investments.fiscal.table_heading.share_numbers_at_end_of_year",
			),
			accessor: (data) => (
				<Tooltip
					content={`Download certificaat voor obligatienummers: ${data.shareNumbers}`}
				>
					<a
						href={data.certificateUrl}
						className="flex text-sm underline hover:text-gray-500"
					>
						<FiDownload className="mr-2" />
						<span className="flex-1">{data.shareNumbers}</span>
					</a>
				</Tooltip>
			),
		},
	]

	return (
		<>
			<Table className="min-w-[48rem] lg:min-w-0">
				{/* table header */}
				<TableHead>
					<tr role="row">
						{columns.map((header) => {
							const isSorted =
								header.sort && sort?.endsWith(header.sort)
							const isSortedDesc =
								isSorted && sort?.startsWith("-")

							return (
								<TableHeading
									as="th"
									key={header.title}
									colSpan={1}
									variant={
										header.sort ? "sortable" : "static"
									}
									role="columnheader"
									title={header.title}
									isSorted={Boolean(isSorted)}
									isSortedDesc={Boolean(isSortedDesc)}
									onClick={() => {
										if (!header.sort) {
											return
										}

										if (!isSorted && !isSortedDesc) {
											setSort(`-${header.sort}`)
										} else if (isSortedDesc) {
											setSort(header.sort)
										} else {
											setSort("")
										}
									}}
								>
									{header.title}
								</TableHeading>
							)
						})}
					</tr>
				</TableHead>
				{/* table body and table cells */}
				<TableBody
					role="rowgroup"
					className={isPreviousData ? "opacity-25" : ""}
					data-testid="tablebody"
				>
					{rows?.map(({ ...row }, index) => {
						return (
							<TableRowCell
								key={row.id}
								isOdd={index % 2 === 0}
								role="row"
								data-testid={`tablerow-${row.id}`}
							>
								{columns.map((column) => {
									return (
										<TableDataCell
											key={column.title}
											className="break-word whitespace-pre-wrap"
										>
											{column.accessor(row as Data)}
										</TableDataCell>
									)
								})}
							</TableRowCell>
						)
					})}
				</TableBody>
			</Table>
			{rows?.length === 0 && (
				<CardBody>
					<TableEmptyState />
				</CardBody>
			)}
		</>
	)
}
const TableEmptyState = () => {
	const t = useTrans("investments")

	return (
		<div className="space-y-4 p-10 text-center">
			<Heading as="h2" styleAs="h5">
				{t("investments:investments.fiscal.no_results.title")}
			</Heading>
			<p className="text-gray-500">
				{t("investments:investments.fiscal.no_results.copy")}
			</p>
		</div>
	)
}
