// Translations
import { useTrans } from "@/i18n"

/**
 * Footer
 */
export function Footer() {
	const t = useTrans()

	return (
		<div className="flex-1 px-4 pb-6 sm:px-7">
			<div className="flex max-w-7xl">
				<div className="flex-1">
					{" "}
					&copy; {t("common:common.brand.name")} 2025
				</div>
			</div>
		</div>
	)
}
