// React
import { useContext, useMemo, useState, Fragment } from "react"

// UI
import { classNames } from "@/lib/classnames"
import {
	PaginationAsButtons,
	PaginationAsButtonsLoadingState,
} from "@/components/PaginationAsButtons"

// Dates
import { DateTime } from "@/lib/dates"

// Context
import { InvestmentsContext } from "../Investments"

// Redux state
import { useSelector } from "@/state/StateProvider"

// GraphQL
import { useInvestmentsTableViewQuery, ProjectType } from "@/api/graphql"

// Types
import {
	ProjectSortOrder,
	InvestmentsOverviewState,
} from "@/state/features/investmentsOverview/types"

// Translations
import { useLang } from "@/context/lang"
import { useTrans } from "@/i18n"

// Tables
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableRowCell,
	TableHeading,
} from "@/components/table-controls/TableItems"
import {
	CellProps,
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
	UseTableOptions,
} from "react-table"

// UI
import { CardBody, CardWrapper } from "@/components/Card"

// Utils
import { truncateFloatNumber } from "@/utils/helpers"

/**
 * InvestmentsTable
 * @returns
 */
export function InvestmentsTable() {
	// Context
	const { search } = useContext(InvestmentsContext)

	// Redux state
	const {
		filterState,
		filterType,
		sortingMethod,
		sortingOrder,
		perPage: limit,
	} = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	// Translations
	const { config, formatCurrency, formatNumber } = useLang()
	const t = useTrans(["project"])

	// State
	const [currentPage, setCurrentPage] = useState<number>(0)

	// Query
	const { data } = useInvestmentsTableViewQuery({
		limit,
		offset: currentPage * limit,
		name: search,
		ordering:
			sortingOrder === ProjectSortOrder.Asc
				? sortingMethod
				: `-${sortingMethod}`,
		state: filterState,
		type: filterType,
	})

	// Projects
	const projects = useMemo(
		() => data?.me?.investment_projects?.results ?? [],
		[data?.me?.investment_projects?.results],
	)

	const columns = useMemo(() => {
		const cols: UseTableOptions<ProjectType>["columns"] = [
			{
				id: "project-name",
				accessor: (data) => data?.name,
				Header: () => (
					<TableHeading>
						{t(
							"investments:investments.overview.table.header.project",
						)}
					</TableHeading>
				),
				Cell: ({ value, cell }: CellProps<ProjectType, string>) => (
					<TableDataCell {...cell.getCellProps()}>
						{value}
					</TableDataCell>
				),
			},
			{
				id: "initial-investment",
				accessor: (data) =>
					Number(
						(data?.investor_shares_value_stats?.shares?.[0]
							?.nominal_value || 0) *
							(data?.investor_shares_value_stats?.total_shares ||
								0),
					),
				Header: () => (
					<TableHeading>
						{t(
							"investments:investments.overview.table.header.initial-investment",
						)}
					</TableHeading>
				),
				Cell: ({ value, cell }: CellProps<ProjectType, number>) => (
					<TableDataCell {...cell.getCellProps()}>
						{formatCurrency(value)}
					</TableDataCell>
				),
			},
			{
				id: "current-value",
				accessor: (data) =>
					Number(
						data?.investor_shares_value_stats
							?.total_investment_for_project,
					).toFixed(2),
				Header: () => (
					<TableHeading>
						{t(
							"investments:investments.overview.table.header.current-value",
						)}
					</TableHeading>
				),
				Cell: ({ value, cell }: CellProps<ProjectType, number>) => (
					<TableDataCell {...cell.getCellProps()}>
						{formatCurrency(value)}
					</TableDataCell>
				),
			},
			{
				id: "total-repayment",
				accessor: (data) =>
					data?.investor_shares_value_stats?.total_repaid_for_project
						? parseFloat(
								data.investor_shares_value_stats
									.total_repaid_for_project,
						  )
						: 0,
				Header: () => (
					<TableHeading>
						{t(
							"investments:investments.overview.table.header.total-repayment",
						)}
					</TableHeading>
				),
				Cell: ({ value, cell }: CellProps<ProjectType, number>) => (
					<TableDataCell {...cell.getCellProps()}>
						{formatCurrency(value)}
					</TableDataCell>
				),
			},
			{
				id: "expected-interest-rate-fixed",
				accessor: (data) =>
					truncateFloatNumber(
						Number(
							data?.investor_shares_value_stats
								?.internal_rate_of_returns
								?.expected_internal_rate_of_return,
						),
					),
				Header: () => (
					<TableHeading>
						{t(
							"investments:investments.overview.table.header.expected-interest-rate-fixed",
						)}
					</TableHeading>
				),
				Cell: ({ value, cell }: CellProps<ProjectType, string>) => (
					<TableDataCell {...cell.getCellProps()}>
						{`${value}%`}
					</TableDataCell>
				),
			},
			{
				id: "interest-rate-fixed",
				accessor: (data) =>
					truncateFloatNumber(
						Number(
							data?.investor_shares_value_stats
								?.internal_rate_of_returns
								?.internal_rate_of_return,
						),
					),
				Header: () => (
					<TableHeading>
						{t(
							"investments:investments.overview.table.header.interest-rate-fixed",
						)}
					</TableHeading>
				),
				Cell: ({ value, cell }: CellProps<ProjectType, string>) => (
					<TableDataCell {...cell.getCellProps()}>
						{`${value}%`}
					</TableDataCell>
				),
			},
			{
				id: "total-kwh-produced",
				accessor: (data) =>
					formatNumber(
						parseFloat(
							(data?.investor_production_stats
								?.investor_generated_power_in_kwh
								? parseFloat(
										data.investor_production_stats
											.investor_generated_power_in_kwh,
								  )
								: 0
							).toFixed(1),
						),
						{
							minimumFractionDigits: 1,
						},
					),
				Header: () => (
					<TableHeading>
						{t(
							"investments:investments.overview.table.header.total-kwh-produced",
						)}
					</TableHeading>
				),
				Cell: ({ value, cell }: CellProps<ProjectType, string>) => (
					<TableDataCell {...cell.getCellProps()}>
						{`${value}kWh`}
					</TableDataCell>
				),
			},
			{
				id: "current_interest_period.end",
				accessor: (project) => project?.current_interest_period?.end,
				Header: () => (
					<TableHeading>
						{t(
							"investments:investments.overview.table.header.current_interest_period",
						)}
					</TableHeading>
				),
				Cell: ({ value, cell }: CellProps<ProjectType, string>) => (
					<TableDataCell {...cell.getCellProps()}>
						{/* @ts-ignore */}
						{value
							? DateTime.fromISO(value).toFormat(
									config.dateFormatProjectCardInterestPeriod,
							  )
							: "-"}
					</TableDataCell>
				),
			},
		]

		return cols.filter(Boolean)
	}, [])

	// Table
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		state: { pageSize, pageIndex },
		prepareRow,
	} = useTable(
		{
			columns,
			data: projects as ProjectType[],
			defaultColumn: {
				Cell: ({ value }: CellProps<ProjectType, string | number>) => (
					<TableDataCell>{value}</TableDataCell>
				),
			},
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
	)

	return (
		<CardWrapper className="mt-4">
			<CardBody>
				<Table
					{...getTableProps({
						className: "min-w-[64rem] lg:min-w-0",
					})}
					data-testid="tablebody"
				>
					<TableHead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => {
									const { key } = column.getHeaderProps()

									return (
										<Fragment key={key}>
											{column.render("Header")}
										</Fragment>
									)
								})}
							</tr>
						))}
					</TableHead>
					<TableBody
						{...getTableBodyProps()}
						data-testid="tablebody-overview"
						data-pageindex={pageIndex}
					>
						{page.map((row) => {
							prepareRow(row)

							const isOdd = row.index % 2 === 0
							let className = ""

							return (
								<Fragment key={row.id}>
									<TableRowCell
										isOdd={isOdd}
										className={classNames(className)}
									>
										{row.cells.map((cell) => {
											const { key } = cell.getCellProps()
											return (
												<Fragment key={key}>
													{cell.render("Cell")}
												</Fragment>
											)
										})}
									</TableRowCell>
								</Fragment>
							)
						})}
						{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
						{page.length < pageSize && pageIndex !== 0 ? (
							<>
								{Array(Math.max(pageSize - page.length, 1))
									.fill(true)
									.map((_, index) => (
										<TableRowCell
											key={index}
											withHover={false}
											isOdd={index % 2 === 0}
										>
											<TableDataCell
												colSpan={columns.length}
											>
												<span className="dummy-text" />
											</TableDataCell>
										</TableRowCell>
									))}
							</>
						) : null}
					</TableBody>
				</Table>

				{projects?.length !== 0 && (
					<div className="mt-6 flex w-full justify-center px-4">
						<PaginationAsButtons
							countPerPage={limit}
							totalCount={
								data?.me?.investment_projects?.totalCount ?? 0
							}
							itemType={"common.pagination.item_types.investment"}
							currentPage={currentPage + 1}
							currentItemsAmount={
								data?.me?.investment_projects?.results
									?.length ?? 0
							}
							onNextPage={() =>
								setCurrentPage((currentPage) => currentPage + 1)
							}
							onPrevPage={() =>
								setCurrentPage((currentPage) =>
									Math.max(currentPage - 1, 0),
								)
							}
							analyticsId="investments"
						/>
					</div>
				)}
			</CardBody>
		</CardWrapper>
	)
}

/**
 * InvestmentsTableLoadingState
 * @returns
 */
export function InvestmentsTableLoadingState() {
	const t = useTrans()

	return (
		<CardWrapper className="mt-4">
			<CardBody>
				<Table>
					<TableHead>
						<tr>
							<TableHeading>
								{t(
									"investments:investments.overview.table.header.project",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"investments:investments.overview.table.header.initial-investment",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"investments:investments.overview.table.header.current-value",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"investments:investments.overview.table.header.total-repayment",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"investments:investments.overview.table.header.expected-interest-rate-fixed",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"investments:investments.overview.table.header.interest-rate-fixed",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"investments:investments.overview.table.header.total-kwh-produced",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"investments:investments.overview.table.header.current_interest_period",
								)}
							</TableHeading>
						</tr>
					</TableHead>
				</Table>
				<div className="mt-6 flex justify-center md:mt-8 md:gap-x-4">
					<PaginationAsButtonsLoadingState />
				</div>
			</CardBody>
		</CardWrapper>
	)
}
