// React
import { useEffect, useState } from "react"

// Navigation
import { Routes } from "@/constants/routes"
import { useNavigate } from "@/lib/router"

// SEO
import { Helmet } from "@/lib/seo"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// DateTime
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/constants/constants"

// UI
import { Button } from "@/components/Button"
import { Heading } from "@/components/Typography"
import { CardBody, CardWrapper, Card } from "@/components/Card"
import { useToasts } from "@/context/toasts"
import { TableWarning } from "@/components/table-controls/TableWarning"
import { ConfirmDialog } from "@/components/dialogs/ConfirmDialog"

// Icons
import { CheckIcon } from "@heroicons/react/outline"

// Context
import { useInvestmentTests } from "@/context/investmentTests"
import { useCurrentUserId } from "@/context/user"
import { useHiddenGetParamsContext } from "@/context/hiddenGetParams"

// GraphQL
import {
	CoreInvestmentTestCategoryChoices,
	CoreInvestmentTestExperienceChoices,
	useInvestorTestMutationsInputMutation,
} from "@/api/graphql"

// Environment variables
import { API_URL } from "@/lib/env"

/**
 * InvestmentExperience
 * @returns
 */
export const InvestorProfile = () => {
	const toasts = useToasts()

	// Context
	const { investorProfileExperience, refetch } = useInvestmentTests()
	const { id: userId } = useCurrentUserId()
	const { next } = useHiddenGetParamsContext()

	// State
	const [showLanguageDisclaimer, setShowLanguageDisclaimer] =
		useState<boolean>(false)
	const [confirmDialogIsOpen, setConfirmDialogIsOpen] =
		useState<boolean>(false)
	const [investorExperienceLevelChoice, setInvestorExperienceLevelChoice] =
		useState<CoreInvestmentTestExperienceChoices>(
			CoreInvestmentTestExperienceChoices.Novice,
		)

	// Translations
	const t = useTrans("investor")
	const { lang, setLang } = useLang()

	// Hack to always reset to lang=NL on this page because we don't have the legal english texts yet
	useEffect(() => {
		if (lang !== "nl") {
			setShowLanguageDisclaimer(true)
			setLang("nl")
		}
	}, [lang, setLang])

	// Mutation
	const createNewTestSubmission = useInvestorTestMutationsInputMutation()

	// When picked investor experience level
	async function handleChoiceExpertInvestor() {
		const response = await createNewTestSubmission.mutateAsync({
			input: {
				investor: userId || "",
				category: CoreInvestmentTestCategoryChoices.Experience,
				experience: CoreInvestmentTestExperienceChoices.Expert,
			},
		})
		// do we have data? then success
		if (response?.investment_test_update?.investor_test?.id) {
			toasts.addToast({
				variant: "success",
				id: `notifications-success-${Date.now()}`,
				text: t(
					"Uw investeringsprofiel is bijgewerkt naar: ervaren belegger",
				),
			})

			// Refetch data
			refetch()

			// Scroll to top
			window.scrollTo(0, 0)

			// When 'next' param is set in context, redirect there in the API
			if (next) {
				window.location.href = `${API_URL}${next}`
			}
		}
	}
	async function handleChoiceNoviceInvestor() {
		const response = await createNewTestSubmission.mutateAsync({
			input: {
				investor: userId || "",
				category: CoreInvestmentTestCategoryChoices.Experience,
				experience: CoreInvestmentTestExperienceChoices.Novice,
			},
		})
		// do we have data? then success
		if (response?.investment_test_update?.investor_test?.id) {
			toasts.addToast({
				variant: "success",
				id: `notifications-success-${Date.now()}`,
				text: t(
					"Uw investeringsprofiel is bijgewerkt naar: niet-ervaren belegger",
				),
			})
			refetch()
		}
	}

	return (
		<>
			<Helmet>
				<title>{t("investor:investor.profile.title")}</title>
			</Helmet>

			<ConfirmDialog
				isOpen={confirmDialogIsOpen}
				onClose={() => setConfirmDialogIsOpen(false)}
				onConfirm={() => {
					if (
						investorExperienceLevelChoice ===
						CoreInvestmentTestExperienceChoices.Novice
					) {
						handleChoiceNoviceInvestor()
					} else {
						handleChoiceExpertInvestor()
					}
					setConfirmDialogIsOpen(false)
				}}
				description={
					{
						NOVICE: t(
							"investor:investor.profile.choice.novice.copy.confirm.novice",
						),
						EXPERT: t(
							"investor:investor.profile.choice.novice.copy.confirm.expert",
						),
					}[investorExperienceLevelChoice]
				}
			/>

			<CardWrapper className="relative">
				{Boolean(investorProfileExperience) === true && (
					<StepCompletedOverlay />
				)}

				<CardBody>
					<Heading as="h2" styleAs="h5" className="mb-3 sm:truncate">
						{t("investor:investor.profile.heading")}
					</Heading>

					{showLanguageDisclaimer === true && (
						<TableWarning
							className="mb-3"
							title={t(
								"investor:investor.generic.legal-disclaimer.dutch.title",
							)}
							message={t(
								"investor:investor.generic.legal-disclaimer-dutch.message",
							)}
						/>
					)}

					<div className="flex flex-col gap-4 md:gap-8 lg:flex-row">
						<div className="bg-gray-card flex w-full flex-col items-start justify-center rounded-md border border-gray-300 p-4 lg:w-1/2">
							<p className="flex-grow">
								<strong className="font-bold">
									{t(
										"investor:investor.profile.choice.novice.title",
									)}
								</strong>
								<br />
								{t(
									"investor:investor.profile.choice.novice.copy.p1",
								)}
								<br /> <br />
								{t(
									"investor:investor.profile.choice.novice.copy.p2",
								)}
							</p>

							<Button
								onClick={() => {
									setInvestorExperienceLevelChoice(
										CoreInvestmentTestExperienceChoices.Novice,
									)
									setConfirmDialogIsOpen(true)
								}}
								className="flex-end flex"
							>
								{t(
									"investor:investor.profile.choice.novice.button",
								)}
							</Button>
						</div>

						<div className="bg-gray-card flex w-full flex-col items-start justify-center rounded-md border border-gray-300 p-4 lg:w-1/2">
							<div className="flex-grow">
								<strong className="font-bold">
									{t(
										"investor:investor.profile.choice.expert.title",
									)}
								</strong>
								<br />
								{t(
									"investor:investor.profile.choice.expert.subtitle",
								)}
								<br /> <br /> 1.{" "}
								<strong className="font-bold">
									{t(
										"investor:investor.profile.choice.expert.business.title",
									)}
								</strong>{" "}
								{t(
									"investor:investor.profile.choice.expert.business.list.item1",
								)}{" "}
								<br /> <br />
								<strong className="font-bold">
									{t("investor:investor.profile.generic.or")}
								</strong>{" "}
								<br />
								<br />
								2.{" "}
								<strong className="font-bold">
									{t(
										"investor:investor.profile.choice.expert.private.title",
									)}
								</strong>{" "}
								{t(
									"investor:investor.profile.choice.expert.private.subtitle",
								)}
								<div className="ml-4">
									<ul className="ml-0 list-disc">
										<li>
											{t(
												"investor:investor.profile.choice.expert.private.list.item1",
											)}
										</li>
										<li>
											{t(
												"investor:investor.profile.choice.expert.private.list.item2",
											)}
										</li>
										<li>
											{t(
												"investor:investor.profile.choice.expert.private.list.item3",
											)}
										</li>
									</ul>
								</div>
							</div>
							<Button
								className="flex-end mt-4 flex"
								onClick={() => {
									setInvestorExperienceLevelChoice(
										CoreInvestmentTestExperienceChoices.Expert,
									)
									setConfirmDialogIsOpen(true)
								}}
							>
								{t(
									"investor:investor.profile.choice.expert.button",
								)}
							</Button>
						</div>
					</div>
				</CardBody>
			</CardWrapper>
		</>
	)
}

const StepCompletedOverlay = () => {
	// i18n
	const t = useTrans("investor")

	// Navigate
	const navigate = useNavigate()

	// Context
	const {
		amountOfRequiredActionsLeft,
		requiredActions,
		investorProfileExperience,
	} = useInvestmentTests()

	// When clicking next button, determine next step
	function onClickButton() {
		const nextStep = Object.keys(requiredActions).find(
			(key) =>
				requiredActions[key as CoreInvestmentTestCategoryChoices] ===
				true,
		)
		switch (nextStep) {
			case "KYC":
				navigate(Routes.InvestorIdentity)
				break
			case "EXPERIENCE":
				navigate(Routes.InvestorProfile)
				break
			case "QUESTIONS":
				navigate(Routes.InvestorTest)
				break
			case "LOSS_CALCULATOR":
				navigate(Routes.InvestorRisk)
		}
	}

	// Template
	return (
		<div className="absolute inset-0 z-10 bg-gray-600 bg-opacity-50 p-5 text-center text-lg">
			<Card className="mx-auto max-w-md">
				<div className="space-y-4 text-center">
					<Heading
						as="h2"
						styleAs="h5"
						className="flex justify-center gap-2"
					>
						<div className="flex items-center">
							<CheckIcon
								aria-hidden="true"
								className="h-5 w-5 text-lg text-green-500"
							/>
						</div>
						Voltooid op{" "}
						{DateTime.fromISO(
							investorProfileExperience?.created_at,
						).toFormat(dateFormat)}
					</Heading>
					<p className="text-gray-500">
						Uw keuze was:{" "}
						{
							{
								NOVICE: "niet-ervaren belegger",
								EXPERT: "ervaren belegger",
							}[
								investorProfileExperience?.experience ||
									CoreInvestmentTestExperienceChoices.Novice
							]
						}
					</p>
					{amountOfRequiredActionsLeft !== undefined &&
						amountOfRequiredActionsLeft !== 0 && (
							<Button onClick={() => onClickButton()}>
								{t("investor:investor.profile.generic.button")}
							</Button>
						)}
				</div>
			</Card>
		</div>
	)
}
