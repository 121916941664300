// React
import { useMemo, ReactNode, ComponentType, useContext } from "react"

// DateTime
import { DateTime, DEFAULT_TIMEZONE } from "@/lib/dates"
import { apiDateFormat } from "@/constants/constants"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Utils
import { getShareNumberRange } from "@/utils/helpers"

// GraphQL
import { useFiscalOverviewByYearQuery } from "@/api/graphql"

// UI
import { CardBody } from "@/components/Card"
import {
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import { Heading, Subheading } from "@/components/Typography"

// Hooks
import { useTableMetaData } from "@/hooks/useTableMetaData"

// Utils
import { isWholeNumber } from "@/lib/math"

// Context
import { FiscalOverviewByYearContext } from "./FiscalOverviewByYear"

function useData() {
	return useFiscalOverviewByYearQueryWithYear()
}

function useFiscalOverviewByYearQueryWithYear(
	options = { keepPreviousData: false },
) {
	const mode = useContext(FiscalOverviewByYearContext)

	const start: string = useMemo(() => {
		if (mode.type === "custom") {
			return DateTime.fromISO(mode.from).toFormat(apiDateFormat)
		}

		// we need to get the date in NL, so we can set the timezone to Amsterdam
		// then we get the start of the year, and then the backend expects the date in UTC
		// note: i had to set millisecond: 0 otherwise it was coming out in the iso timestamp
		return DateTime.local()
			.setZone(DEFAULT_TIMEZONE)
			.set({
				year: parseInt(mode.year),
			})
			.startOf("year")
			.toFormat(apiDateFormat)
	}, [mode])

	const end: string = useMemo(() => {
		if (mode.type === "custom") {
			return DateTime.fromISO(mode.to).toFormat(apiDateFormat)
		}

		// we can safely use the beginning of next year since the end date is exlusive
		return DateTime.local()
			.setZone(DEFAULT_TIMEZONE)
			.set({
				year: parseInt(mode.year) + 1,
			})
			.startOf("year")
			.toFormat(apiDateFormat)
	}, [mode])

	return useFiscalOverviewByYearQuery(
		{
			start,
			end,
		},
		options,
	)
}

// Types
type Data = {
	projectName?: string
	sharesAtStart?: number
	sharesAtEnd?: number
	shareNumbersAtEnd: string
	valueAtStart?: number
	valueAtEnd?: number
}

type Columns<Data> = Array<{
	title: string
	sort?: string
	accessor: (data: Data) => ReactNode
	Footer?: ComponentType<{ rows: Array<Data> }>
	className?: string
	component?: (props: { payment: Data }) => ReactNode
}>

/**
 * FiscalOverviewByYearTable
 * @returns
 */
export function FiscalOverviewByYearTable() {
	const { formatCurrency } = useLang()
	const t = useTrans("investments")
	const { setSort, sort } = useTableMetaData()
	const { data, isPreviousData } = useData()

	const rows = useMemo(() => {
		if (!data?.me?.investment_projects) {
			return []
		}
		return data.me.investment_projects?.results?.map((project) => {
			return {
				projectId: project?.id ?? "",
				projectName: project?.name ?? "",
				sharesAtStart: project?.start?.total_shares ?? undefined,
				valueAtStart: project?.start?.total_investment_value
					? parseFloat(project?.start?.total_investment_value)
					: undefined,
				sharesAtEnd: project?.end?.total_shares ?? undefined,
				shareNumbersAtEnd:
					getShareNumberRange(
						project?.end?.shares?.map((share) =>
							Number(share?.share_number),
						),
						t,
					) ?? undefined,
				valueAtEnd: project?.end?.total_investment_value
					? parseFloat(project.end?.total_investment_value)
					: undefined,
			}
		})
	}, [data?.me?.investment_projects])

	const columns: Columns<Data> = [
		{
			title: t("investments.fiscal.table_heading.project_name"),
			accessor: (data) => data.projectName,
		},
		{
			title: t("investments.fiscal.table_heading.number_of_shares"),
			accessor: (data) =>
				data.sharesAtStart
					? isWholeNumber(data.sharesAtStart)
						? data.sharesAtStart
						: Number(data.sharesAtStart ?? 0).toFixed(2)
					: "-",
		},
		{
			title: t(
				"investments.fiscal.table_heading.number_of_shares_at_end_of_year",
			),
			accessor: (data) =>
				data.sharesAtEnd
					? isWholeNumber(data.sharesAtEnd)
						? data.sharesAtEnd
						: Number(data.sharesAtEnd ?? 0).toFixed(2)
					: "-",
		},
		{
			title: t("investments.fiscal.table_heading.value_at_start_of_year"),
			accessor: (data) =>
				data.valueAtStart
					? formatCurrency(data.valueAtStart ?? 0)
					: "-",
			Footer: ({ rows }) => {
				const total = useMemo(
					() =>
						rows.reduce((sum, row) => {
							if (row.valueAtStart) return row.valueAtStart + sum
							return sum
						}, 0),
					[rows],
				)

				return (
					<div className="break-word whitespace-pre-wrap">
						<Subheading className="mb-2">
							{t(
								"investments.fiscal.table_footer.value_at_start_of_year",
							)}
						</Subheading>
						<p className="text-sm font-medium text-gray-700">
							{formatCurrency(total)}
						</p>
					</div>
				)
			},
		},
		{
			title: t("investments.fiscal.table_heading.value_at_end_of_year"),
			accessor: (data) =>
				data.valueAtEnd ? formatCurrency(data.valueAtEnd) : "-",
			Footer: ({ rows }) => {
				const total = useMemo(
					() =>
						rows.reduce((sum, row) => {
							if (row.valueAtEnd) return row.valueAtEnd + sum
							return sum
						}, 0),
					[rows],
				)

				return (
					<div className="break-word whitespace-pre-wrap">
						<Subheading className="mb-2">
							{t(
								"investments.fiscal.table_footer.value_at_end_of_year_total",
							)}
						</Subheading>
						<p className="text-sm font-medium text-gray-700">
							{formatCurrency(total)}
						</p>
					</div>
				)
			},
		},
		{
			title: t(
				"investments.fiscal.table_heading.share_numbers_at_end_of_year",
			),
			accessor: (data) => <>{data.shareNumbersAtEnd}</>,
		},
	]

	return (
		<>
			{/** Using default HTML table instead of TableItems to prevent scrollbar */}
			<table className="w-full">
				{/* table header */}
				<TableHead>
					<tr role="row">
						{columns.map((header) => {
							const isSorted =
								header.sort && sort?.endsWith(header.sort)
							const isSortedDesc =
								isSorted && sort?.startsWith("-")

							return (
								<TableHeading
									as="th"
									key={header.title}
									colSpan={1}
									variant={
										header.sort ? "sortable" : "static"
									}
									role="columnheader"
									title={header.title}
									isSorted={Boolean(isSorted)}
									isSortedDesc={Boolean(isSortedDesc)}
									onClick={() => {
										if (!header.sort) {
											return
										}

										if (!isSorted && !isSortedDesc) {
											setSort(`-${header.sort}`)
										} else if (isSortedDesc) {
											setSort(header.sort)
										} else {
											setSort("")
										}
									}}
								>
									{header.title}
								</TableHeading>
							)
						})}
					</tr>
				</TableHead>
				{/* table body and table cells */}
				<TableBody
					role="rowgroup"
					className={isPreviousData ? "opacity-25" : ""}
					data-testid="tablebody"
				>
					{rows?.map(({ projectId, ...row }, index) => {
						return (
							<TableRowCell
								key={projectId}
								isOdd={index % 2 === 0}
								role="row"
								data-testid={`tablerow-${projectId}`}
							>
								{columns.map((column) => {
									return (
										<TableDataCell
											key={column.title}
											className="break-word whitespace-pre-wrap"
										>
											{column.accessor(row)}
										</TableDataCell>
									)
								})}
							</TableRowCell>
						)
					})}
				</TableBody>
				{rows && rows.length > 0 ? (
					<tfoot>
						<tr>
							{columns.map((column) => {
								if (column.Footer) {
									return (
										<TableDataCell
											key={column.title}
											data-testid={`footer-${column.title}`}
											className="text-gray-600"
										>
											<column.Footer rows={rows} />
										</TableDataCell>
									)
								}

								return <TableDataCell key={column.title} />
							})}
						</tr>
					</tfoot>
				) : null}
			</table>
			{rows?.length === 0 && (
				<CardBody>
					<TableEmptyState />
				</CardBody>
			)}
		</>
	)
}
const TableEmptyState = () => {
	const t = useTrans("investments")

	return (
		<div className="space-y-4 p-10 text-center">
			<Heading as="h2" styleAs="h5">
				{t("investments.fiscal.no_results.title")}
			</Heading>
			<p className="text-gray-500">
				{t("investments.fiscal.no_results.copy")}
			</p>
		</div>
	)
}
