import { useEffect, useState } from "react"
import { Trans, useTrans } from "@/i18n"
import {
	FiCheckSquare,
	FiChevronDown,
	FiChevronUp,
	FiShield,
} from "react-icons/fi"
import { AnimatePresence, motion } from "@/lib/animations"
import { TwoFactorAuthState } from "./TwoFactorAuthState"
import { SMSMethodConfirmPhoneNumber } from "./SMS/SMSMethodConfirmPhoneNumber"
import { SMSMethodCodeVerification } from "./SMS/SMSMethodCodeVerification"
import { EmailMethodConfirmAddress } from "./email/EmailMethodConfirmAddress"
import { EmailMethodCodeVerification } from "./email/EmailMethodCodeVerification"

// Queries
import {
	apiAuthCodeRequestCreate,
	useApiAuthMfaUserActiveMethodsList,
	useApiAuthMfaConfigRetrieve,
	apiAuthDeactivateCreate,
} from "@/api/rest/generated/api/api"

// UI
import { useToasts } from "@/context/toasts"
import { Button } from "@/components/Button"
import { ConfirmDialog } from "@/components/dialogs/ConfirmDialog"
import { Select, SelectButton } from "@/components/form-controls"
import { PromptDialog } from "@/components/dialogs/PromptDialog"

// Types
import {
	MethodEnum,
	ConfigResponse,
	UserMFAMethod,
} from "@/api/rest/generated/@types"

const METHOD_SMS = "sms_api"
const METHOD_EMAIL = "email"
const METHOD_NONE = "select_method"
export const METHODS_COUNT = 2
export const VERIFICATION_CODE_LENGTH = 6

export const TwoFactorAuthSettings = () => {
	const t = useTrans("profile")

	const [isPromptDialogOpen, setPromptDialogOpen] = useState<boolean>(false)
	const [isConfirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false)

	const [userMfaState, setUserMfaState] = useState<UserMFAMethod[]>([])
	const [mfaState, setMfaState] = useState<ConfigResponse>()
	const [selectedMethod, setSelectedMethod] = useState<string>(METHOD_NONE)
	const [step, setStep] = useState<number | undefined>()
	const [showAddForm, setShowAddForm] = useState<boolean>(false)
	const [primaryMethod, setPrimaryMethod] = useState<MethodEnum>()

	const toasts = useToasts()
	const commonTrans = useTrans("common")

	const {
		isSuccess: isMfaQuerySuccess,
		data: dataMfa,
		refetch: refetchMfaConfig,
	} = useApiAuthMfaConfigRetrieve()

	useEffect(() => {
		if (mfaState) {
			const primaryMethodName = userMfaState
				.filter((method) => method.is_primary)
				.at(0)?.name

			setPrimaryMethod(primaryMethodName as MethodEnum)
		}
	}, [mfaState, userMfaState])

	useEffect(() => {
		if (isMfaQuerySuccess) {
			// @ts-ignore
			setMfaState(dataMfa)
		}
	}, [dataMfa, isMfaQuerySuccess])

	const { isSuccess, data, refetch } = useApiAuthMfaUserActiveMethodsList()

	useEffect(() => {
		if (isSuccess) {
			setUserMfaState(data)
		}
	}, [data, isSuccess])

	const isMfaEnabled = (): boolean => {
		return !!mfaState?.methods.length
	}

	const isMethodAvailable = (methodName: MethodEnum): boolean => {
		return !!mfaState?.methods.some(
			(methodName_) => methodName === methodName_,
		)
	}

	const isMethodEnabled = (methodName: MethodEnum): boolean => {
		return !!userMfaState.some((method) => methodName === method.name)
	}

	const canEnable2FAMethod = () => {
		return userMfaState.length < METHODS_COUNT && !!isMfaEnabled()
	}

	const deactivateMethodRequest = async () => {
		try {
			await apiAuthCodeRequestCreate({ method: primaryMethod })
		} catch (e) {
			toasts.addToast({
				variant: "alert",
				id: `changePrimaryMethodRequest`,
				text: commonTrans(`common.request_failed`),
			})
		}
	}

	const handleDeactivateMfa = (value: string) => {
		apiAuthDeactivateCreate(primaryMethod!, {
			code: value,
		})
			.then(() => {
				refetch()
				refetchMfaConfig()

				toasts.addToast({
					variant: "success",
					id: `handle-deactivate-method-${Date.now()}`,
					text: t(
						`profile:profile.security.two_factor_auth.state.deactivate_mfa_success`,
						{
							method: t(
								`profile.security.mfa_method.${primaryMethod}`,
							),
						},
					),
				})

				setPromptDialogOpen(false)
				setPrimaryMethod(undefined)
			})
			.catch(() => {
				toasts.addToast({
					variant: "alert",
					id: `deactivate-2fa-method-error`,
					text: t(
						`profile:profile.security.verify_code.code.invalid_verification_code`,
					),
				})
			})
	}

	return (
		<>
			<ConfirmDialog
				isOpen={isConfirmDialogOpen}
				onClose={() => setConfirmDialogOpen(false)}
				onConfirm={() => {
					deactivateMethodRequest()
					setConfirmDialogOpen(false)
					setPromptDialogOpen(true)
				}}
				description={t(
					`profile:profile.security.two_factor_auth_intro.disable.confirm`,
				)}
			/>
			<PromptDialog
				isOpen={isPromptDialogOpen}
				onClose={() => setPromptDialogOpen(false)}
				title={t(
					"profile:profile.security.two_factor_auth.state.prompt.enter_received_code.header",
				)}
				prompt={t(
					`profile:profile.security.two_factor_auth.state.prompt.enter_received_code.prompt`,
					{
						method: t(
							`profile:profile.security.mfa_method.${primaryMethod}`,
						),
					},
				)}
				onConfirm={handleDeactivateMfa}
				inputProps={{
					type: "input",
					props: {
						maxLength: VERIFICATION_CODE_LENGTH,
						type: "text",
						hasError: false,
						placeholder: "000000",
					},
				}}
			/>
			<AnimatePresence>
				{!step && (
					<motion.div
						initial={{ marginTop: "-40px", opacity: 0 }}
						animate={{
							marginTop: 0,
							opacity: 1,
							transition: { duration: 0.5 },
						}}
						exit={{
							marginTop: "-40px",
							opacity: 0,
							transition: { duration: 0.5 },
						}}
						key="2fa-toggle"
						className="mb-6 text-gray-500"
					>
						<div className="flex items-center">
							<FiShield className="mr-1" />
							<p className="text-gray-500 md:w-8/12">
								<Trans
									i18nKey={
										userMfaState.length > 0
											? `profile:profile.security.two_factor_toggle.enabled.option.copy`
											: `profile:profile.security.two_factor_toggle.disabled.option.copy`
									}
									ns="profile"
									components={{
										green: (
											<span className="font-medium text-green-700" />
										),
										red: (
											<span className="font-medium text-red-700" />
										),
									}}
								/>
							</p>
						</div>

						{primaryMethod && (
							<div className="flex items-center">
								<FiCheckSquare className="mr-1" />
								<p className="text-gray-500 md:w-8/12">
									<Trans
										i18nKey="profile.security.two_factor_toggle.primary_method"
										ns="profile"
										components={{
											bold: (
												<span className="font-medium" />
											),
										}}
										values={{
											method: t(
												`profile:profile.security.mfa_method.${primaryMethod}`,
											),
										}}
									/>
								</p>
							</div>
						)}

						{!!dataMfa?.methods.length &&
							userMfaState.length === 1 && (
								<div className="mt-2">
									<Button
										variant="error"
										size="xxsmall"
										onClick={() =>
											setConfirmDialogOpen(true)
										}
									>
										{t(
											"profile:profile.security.two_factor_auth.state.btn_disable_2fa",
										)}
									</Button>
								</div>
							)}
					</motion.div>
				)}

				<TwoFactorAuthState />
			</AnimatePresence>

			{(canEnable2FAMethod() || step !== undefined) && (
				<div className="flex flex-col text-sm">
					<div className="text-gray-500">
						{userMfaState.length > 0
							? t(
									"profile:profile.security.two_factor_auth_intro.enable_2fa_method_variant",
							  )
							: t(
									"profile:profile.security.two_factor_auth_intro.enable_2fa_method",
							  )}
					</div>
					<div className="my-3 flex align-middle lg:w-1/2">
						<SelectButton className="relative">
							{selectedMethod !== METHOD_NONE
								? t(
										`profile:profile.security.mfa_method.${selectedMethod}`,
								  )
								: t(
										`profile:profile.security.two_factor_auth.select_method.select_method`,
								  )}
							<FiChevronDown className="ml-2" />
							<Select
								onChange={(evt) => {
									evt.preventDefault()
									if (step && step > 1) {
										setStep(1)
									}
									setSelectedMethod(evt.currentTarget.value)
								}}
								value={selectedMethod}
							>
								<option
									value={METHOD_NONE}
									key={METHOD_NONE}
									defaultValue={METHOD_NONE}
									disabled
								>
									{t(
										`profile:profile.security.two_factor_auth.select_method.select_method`,
									)}
								</option>
								<option
									value={METHOD_SMS}
									key={METHOD_SMS}
									disabled={isMethodEnabled(METHOD_SMS)}
								>
									{t(
										`profile:profile.security.mfa_method.${METHOD_SMS}`,
									)}
								</option>
								<option
									value={METHOD_EMAIL}
									key={METHOD_EMAIL}
									disabled={isMethodEnabled(METHOD_EMAIL)}
								>
									{t(
										`profile:profile.security.mfa_method.${METHOD_EMAIL}`,
									)}
								</option>
							</Select>
						</SelectButton>
					</div>

					<div className="flex">
						<Button
							variant={showAddForm ? "dark" : "primary"}
							onClick={() => {
								setStep(showAddForm ? undefined : 1)
								setShowAddForm(!showAddForm)
								if (showAddForm) {
									setSelectedMethod(METHOD_NONE)
								}
							}}
							disabled={selectedMethod === METHOD_NONE}
							size="xsmall"
							className="w-100 flex"
						>
							{t(
								showAddForm
									? `profile:profile.security.two_factor_auth_intro.link.exit`
									: `profile:profile.security.two_factor_auth_intro.link.start`,
							)}
							{showAddForm ? (
								<FiChevronUp
									className="ml-2 text-2xl"
									aria-hidden="true"
								/>
							) : (
								<FiChevronDown
									className="ml-2 text-2xl"
									aria-hidden="true"
								/>
							)}
						</Button>
					</div>
					{showAddForm && (
						<hr className="my-4 h-px border-0 bg-gray-200 dark:bg-gray-700" />
					)}
				</div>
			)}

			{selectedMethod === METHOD_SMS && isMethodAvailable(METHOD_SMS) && (
				<div className="flex flex-col">
					{step === 1 && (
						<SMSMethodConfirmPhoneNumber
							onSuccess={() => setStep(2)}
						/>
					)}
					{step === 2 && (
						<SMSMethodCodeVerification
							onSuccess={() => {
								refetch()
								refetchMfaConfig()
							}}
							onClose={() => {
								setShowAddForm(false)
								setStep(undefined)
							}}
						/>
					)}
				</div>
			)}
			{selectedMethod === METHOD_EMAIL &&
				isMethodAvailable(METHOD_EMAIL) && (
					<div className="flex flex-col">
						{step === 1 && (
							<EmailMethodConfirmAddress
								onSuccess={() => setStep(2)}
							/>
						)}
						{step === 2 && (
							<EmailMethodCodeVerification
								onSuccess={() => {
									refetch()
									refetchMfaConfig()
								}}
								onClose={() => {
									setShowAddForm(false)
									setStep(undefined)
								}}
							/>
						)}
					</div>
				)}
		</>
	)
}
