import { useMemo, useEffect } from "react"
import { DateTime } from "@/lib/dates"
import { useParams } from "@/lib/router"
import { CumulativeProductionGraph } from "@/components/visx/CumulativeProductionGraph"
import { useFinanceGraphCumulativeProductionQuery } from "@/api/graphql"

// Hooks
import { useCurrentFinanceProject } from "../hooks/useCurrentFinanceProject"
import { useCurrentFinanceProjectId } from "../hooks/useCurrentFinanceProjectId"

// Graphs
import {
	useProductionCumulativeGraphContext,
	AvailableDatePeriods,
} from "@/components/graphs/types/stacked-area/ProductionCumulative"

function useProductionCumulativeGraphData() {
	const id = useCurrentFinanceProjectId()

	const { graphInterval, startTime, endTime, setMinimumTime } =
		useProductionCumulativeGraphContext()
	const params = useParams()

	// Query data
	const { data: projectData } = useCurrentFinanceProject()

	// Set the start date of the project as minimum time
	useEffect(() => {
		if (projectData?.project?.start) {
			setMinimumTime(
				DateTime.fromISO(projectData?.project?.start).toJSDate(),
			)
		}
	}, [projectData?.project?.start, setMinimumTime])

	// Query options
	const queryOptions = useMemo(() => {
		return {
			projectId: params.id,
			startTime,
			endTime,
			// monthly timerange in graph = daily intervals
			// yearly timerange in graph = monthly interals
			// all = monthly interals
			interval:
				graphInterval === AvailableDatePeriods.MONTH ? "day" : "month",
			id: String(id),
		}
	}, [startTime, endTime, params.id, id, graphInterval])

	const { data } = useFinanceGraphCumulativeProductionQuery(queryOptions)

	return data?.me?.finance_projects?.results?.[0]?.overall_statistics ?? {}
}

/**
 * FinanceGraphCumulativeProduction
 * @returns
 */
export function FinanceGraphCumulativeProduction() {
	const { cumulative_production_data, expected_cumulative_production_data } =
		useProductionCumulativeGraphData()

	const cumulativeProductionData = useMemo(() => {
		return (
			cumulative_production_data?.map((data) => {
				return {
					x: data?.date
						? DateTime.fromISO(data.date).toUTC().toMillis()
						: 0,
					y: data?.production ? parseFloat(data.production) : 0,
				}
			}) ?? []
		)
	}, [cumulative_production_data])

	const expectedCumulativeProductionData = useMemo(() => {
		return (
			expected_cumulative_production_data?.map((data) => {
				return {
					x: data?.date
						? DateTime.fromISO(data.date).toUTC().toMillis()
						: 0,
					y: data?.production ? parseFloat(data.production) : 0,
				}
			}) ?? []
		)
	}, [expected_cumulative_production_data])

	return (
		<CumulativeProductionGraph
			data={cumulativeProductionData}
			estimation={expectedCumulativeProductionData}
			topPadding={50}
		/>
	)
}
